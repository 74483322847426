import Vue from "vue";
import Vuex from "vuex";
import {
  infoLoad,
  simulateFinance,
  loadCarByPlate,
  loadCarInfo,
  loadContracts,
  /* newInfoLoad,
  usedInfoLoad, */
  loadInstalations,
  loadCarByVin,
  getFiltersUsed,
  getFiltersNew,
  filterCar,
  filterCarNew,
} from "../services/functions";
import {
  getUniques,
  getAllOidDealers,
  createNewCarsArray,
  mapNewCarArray,
  createUsedCarsArray,
  createInstallationsArray,
  createInstallationSingleArray,
  mergeAll,
  filterWarranty,
  contratosArray,
  mapUsedCar,
  mapNewCar,
} from "../services/dataManagement";

/**
 * VUEX Modules
 */
import user from "./modules/user";
import form from "./modules/form";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    /* NECESSÁRIO DESDE O LOAD DO SITE */
    appReady: false,
    isLoading: false,
    cookieModal: true,
    cookieSettings: "",
    isLexus: process.env.VUE_APP_THEME_PATH == "lexus" ? true : false,

    dealerInfo: [], //informações necessárias para o site sobre o concessionário
    allInstallations: [], //necessário para a página das instalações
    oidDealers: "", //necessário para ir buscar os usados apenas do concessionário em questão
    uniqueInstallations: [], //necessário para listar por distrito?

    ctas: [],
    /* END NECESSÁRIO DESDE O LOAD DO SITE */

    homepage: [],
    homepageDealer: [],
    homepageReady: false,

    novos: [],
    novosCount: 0,
    novosShowMore: false,
    novosContent: [],
    novosSubmenu: [],
    completeData: [],
    novosLegal: "",
    novosSEO: [],
    singleNewCarInfoReady: false,

    filtersNovos: {},
    filtersUsados: {},
    appliedFilters: {
      condition: [2],
    },
    activeFilters: [],
    changeCounter: 0,

    usados: [],
    usadosCount: 0,
    usadosShowMore: false,
    usadosGarantia: [],
    usadosContratos: [],
    usadosContent: [],
    usadosSubmenu: [],
    usadosSEO: [],
    usadosSEOSingle: "",
    usedCar: {},
    onlineBookingText: "",
    testDriveText: "",
    simulatorText: "",
    singleUsedCarInfoReady: false,
    contratosReady: false,
    modelId: "",
    isLoaded: false,

    models: [],

    selectedCar: {},

    campanhasTCAP: [],
    campanhasDealer: [],
    campanhasContent: [],
    campanhasSourceTCAP: "",
    campanhasSourceDealer: "",
    campanhasSEO: [],

    noticiasTCAP: [],
    noticiasDealer: [],
    noticiasContent: [],
    noticiasSourceTCAP: "",
    noticiasSourceDealer: "",
    noticiasSEO: [],

    instalacoesServicos: [],
    instalacoesContent: [],
    instalacoesContentSingle: [],
    instalacoesSEO: [],

    aposVendaContent: [],
    aposVendaSEO: [],

    simulator: {
      acceptanceFee: 0,
      code: -5,
      financialFee: 0,
      idVc: 0,
      isuc: 0,
      kms: 0,
      legalInfo: "",
      message: "OK",
      monthPeriod: 0,
      monthlyFee: 0,
      monthlyPayment: 0,
      mtic: 0,
      taeg: 0,
      tan: 0,
      vfmg: 0,
      vin: null,
    },

    legal: [],
    privacy: [],
    cookies: [],

    contact: [],
    proposal: [],
    testdrive: [],
  },
  getters: {
    //CALLS THE FUNCTION TO CREATE A SMALLER ARRAY WITH ONLY THE IMPORTANT PROPERTIES FOR THE LIST FROM THE STATE WITH THE FULL RESPONSE
    getNewUsedCarsArray: (state, getter) => {
      return createUsedCarsArray(state.usados, getter.getNewInstallationsArray);
    },
    getNewCarsArray: (state, getter) => {
      return createNewCarsArray(state.novos, getter.getNewInstallationsArray);
    },
    getMappedCarArray: (state) => {
      if (state.novos) {
        return mapNewCarArray(state.novos);
      } else {
        return [];
      }
    },
    getMappedUsedCar: (state) => {
      return mapUsedCar(state.selectedCar);
    },
    getMappedNewCar: (state) => {
      return mapNewCar(state.selectedCar);
    },
    getNewInstallationsArray: (state) => {
      return createInstallationsArray(state.allInstallations);
    },
    getNewSingleInstallationArray: (state) => (id) => {
      return createInstallationSingleArray(state.allInstallations, id);
    },
    getAllNews(state) {
      return mergeAll(state.noticiasTCAP, state.noticiasDealer);
    },
    getAllCampaigns(state) {
      return mergeAll(state.campanhasTCAP, state.campanhasDealer);
    },
    getWarranty(state) {
      return filterWarranty(state.usadosGarantia, state.modelId);
    },
    getContratos(state) {
      return contratosArray(state.usadosContratos);
    },
  },
  mutations: {
    //CHANGES THE STATES OF THE PROPERTY WITH THE ARRAY AND IF IT'S READY
    GET_MAIN_INFO(state, response) {
      state.ctas = response.ctas;
      state.dealerInfo = response.dealerInfo;
    },
    GET_HOMEPAGE(state, homepage) {
      state.homepage = homepage;
    },
    GET_HOMEPAGE_DEALER(state, values) {
      state.homepageDealer = values;
    },
    GET_NOVOS_CONTENT(state, values) {
      state.novosLegal = values.data.legalNote;
      state.novosContent = values.data.textBlock;
      state.novosSubmenu = values.data.subMenu;
      state.novosSEO = values.data.seo;
    },
    GET_SINGLENEWCARINFO(state, value) {
      state.singleNewCarInfoReady = true;
      state.modelId = value;
    },
    GET_USADOS(state, values) {
      state.usados = values.usedCars;
      state.isLoaded = true;
    },
    SET_USADOS(state, values) {
      state.usados = values.data.searchResult;
      state.usadosCount = values.count;
      state.usadosShowMore = values.isShowMore;
    },
    SET_NOVOS(state, values) {
      state.novos = values.data;
      state.novosCount = values.count;
      state.novosShowMore = values.isShowMore;
    },
    ADD_USADOS(state, values) {
      state.usados = [...state.usados, ...values.data];
      state.usadosShowMore = values.isShowMore > state.usados.length;
    },
    ADD_NOVOS(state, values) {
      state.novos = [...state.novos, ...values.data];
      state.novosShowMore = values.isShowMore > state.novos.length;
    },
    GET_USADOS_CONTENT(state, values) {
      state.usadosGarantia = values.contracts;
      state.usadosContent = values.content.textBlock;
      state.usadosSubmenu = values.content.subMenu;
      state.usadosSEO = values.content.seo;
      state.onlineBookingText = values.content.single.onlineBooking;
      state.testDriveText = values.content.single.testDrive;
      state.simulatorText = values.content.single.simulator;
    },
    GET_SINGLEUSEDCARINFO(state, value) {
      state.singleUsedCarInfoReady = true;
      state.modelId = value;
    },
    GET_CONTRATOS(state, value) {
      state.contratosReady = true;
      state.usadosContratos = value;
    },
    GET_CAMPANHAS(state, values) {
      state.campanhasTCAP = values.data;
      state.campanhasDealer = values.dataSingle;
      state.campanhasContent = values.content.textBlock;
      state.campanhasSourceTCAP = values.content.tcapSource;
      state.campanhasSourceDealer = values.content.dealerSource;
      state.campanhasSEO = values.content.seo;
    },
    GET_NOTICIAS(state, values) {
      state.noticiasTCAP = values.data;
      state.noticiasDealer = values.dataSingle;
      state.noticiasContent = values.content.textBlock;
      state.noticiasSourceTCAP = values.content.tcapSource;
      state.noticiasSourceDealer = values.content.dealerSource;
      state.noticiasSEO = values.content.seo;
    },
    GET_INSTALACOES_CONTENT(state, values) {
      state.instalacoesServicos = values.data;
      state.instalacoesContent = values.content.textBlock;
      state.instalacoesSEO = values.content.seo;
      state.instalacoesContentSingle = values.content.single.blockTextSingle;
    },
    GET_INSTALACOES(state, values) {
      state.allInstallations = values.installations;
      state.uniqueInstallations = getUniques(values.installations, "oidParent");
      state.oidDealers = getAllOidDealers(values.installations);
      state.appReady = true;
    },
    GET_APOSVENDA(state, values) {
      state.aposVendaContent = values.content;
      state.aposVendaSEO = values.content.seo;
    },
    SIMULATOR(state, values) {
      state.simulator = values;
    },
    GET_LEGALPAGES(state, content) {
      state.legal = content.legal;
      state.privacy = content.privacy;
      state.cookies = content.cookies;
      state.linkComplaint = content?.linkComplaint || {
        url: "https://www.livroreclamacoes.pt/Pedido/Reclamacao",
      };
    },
    GET_FORMPAGES(state, content) {
      state.contact = content.contact;
      state.proposal = content.proposal;
      state.testdrive = content.testdrive;
    },

    RESET_STATE(state, field) {
      state[field.state] = field.value;
    },
    GET_CAR(state, car) {
      state.selectedCar = car;
    },
    SET_FILTERS_NEW(state, values) {
      state.filtersNovos = values.data;
      state.changeCounter++;
    },
    SET_FILTERS_USED(state, values) {
      state.filtersUsados = values.data;
      state.changeCounter++;
    },
    ADD_COUNTER(state) {
      state.changeCounter++;
    },
    ADD_FILTER_CHECKBOX(state, values) {
      // eslint-disable-next-line no-prototype-builtins
      if (state.appliedFilters.hasOwnProperty(values.type)) {
        if (state.appliedFilters[values.type]) {
          state.appliedFilters[values.type] = [
            ...state.appliedFilters[values.type],
            values.id,
          ];
        }
      } else {
        state.appliedFilters[values.type] = [values.id];
      }
      if (state.activeFilters.length > 0) {
        if (state.activeFilters.find((el) => el.id != values.id)) {
          state.activeFilters.push({
            type: values.type,
            value: values.value,
            id: values.id,
          });
        }
      } else {
        state.activeFilters.push({
          type: values.type,
          value: values.value,
          id: values.id,
        });
      }
      state.appliedFilters[values.type] = [
        ...new Set(state.appliedFilters[values.type]),
      ];
      state.activeFilters = state.activeFilters.filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t.id === value.id && t.type === value.type)
      );
      state.changeCounter++;
    },
    ADD_FILTER_RANGE(state, values) {
      state.appliedFilters[values.event.rangeMin] = [values.event.valueMin];
      state.appliedFilters[values.event.rangeMax] = [values.event.valueMax];

      /* if (state.activeFilters.find((el) => el.type == values.type)) {
        state.activeFilters.splice(
          state.activeFilters.findIndex((el) => el.range == values.value.range),
          1
        );
      } */

      if (state.activeFilters.find((el) => el.type == values.type)) {
        state.activeFilters[
          state.activeFilters.findIndex((el) => el.type == values.type)
        ] = {
          type: values.type,
          min: values.event.valueMin,
          max: values.event.valueMax,
        };
      } else {
        state.activeFilters.push({
          type: values.type,
          min: values.event.valueMin,
          max: values.event.valueMax,
        });
      }

      state.changeCounter++;
    },
    REMOVE_FILTER(state, value) {
      if (
        value.type != "year" &&
        value.type != "price" &&
        value.type != "kms" &&
        value.type != "fee"
      ) {
        if (state.appliedFilters[value.type].length > 1) {
          state.appliedFilters[value.type].splice(
            state.appliedFilters[value.type].findIndex(
              (el) => el.id == value.id
            ),
            1
          );
        } else {
          delete state.appliedFilters[value.type];
        }
        state.activeFilters.splice(
          state.activeFilters.findIndex((el) => el.id == value.id),
          1
        );
      } else {
        if (value.type == "price") {
          delete state.appliedFilters["priceSup"];
          delete state.appliedFilters["priceInf"];
        }
        if (value.type == "kms") {
          delete state.appliedFilters["kmSup"];
          delete state.appliedFilters["kmInf"];
        }
        if (value.type == "fee") {
          delete state.appliedFilters["monthlyPaymentSup"];
          delete state.appliedFilters["monthlyPaymentInf"];
        }
        if (value.type == "year") {
          delete state.appliedFilters["yearSup"];
          delete state.appliedFilters["yearInf"];
        }

        state.activeFilters.splice(
          state.activeFilters.findIndex((el) => el.type == value.type),
          1
        );
      }
      state.changeCounter++;
    },
    REMOVE_ALL_FILTER(state, value) {
      state.appliedFilters = {
        condition: [value],
      };
      state.activeFilters = [];
      state.changeCounter++;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_MODELS(state, values) {
      state.models = values;
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async getFilteredInstallations({}, type) {
      console.log("Type", type, "from: getFilteredInstallations");
      await loadInstalations(type).then((response) => {
        this.commit("GET_INSTALACOES", {
          installations: response.data,
        });
      });
    },
    async getInfoLoad() {
      await infoLoad().then((response) => {
        if (response) {
          if (response.code == 1) {
            this.commit("GET_APOSVENDA", {
              content: response.response.afterSalesContent,
            });
            this.commit("GET_MAIN_INFO", {
              dealerInfo: response.response.dealerWPInfo,
              ctas: response.response.ctas,
            });
            this.commit("GET_HOMEPAGE", response.response.homepageContent);
            this.commit(
              "GET_HOMEPAGE_DEALER",
              response.response.homepageContentDealer
            );
            this.commit("GET_USADOS_CONTENT", {
              contracts: response.response.warranty,
              content: response.response.usedCarsContent,
            });
            this.commit("GET_NOVOS_CONTENT", {
              data: response.response.rangeContent,
            });
            this.commit("GET_CAMPANHAS", {
              data: response.response.campaigns,
              dataSingle: response.response.campaignsDealer,
              content: response.response.campaignsContent,
            });
            this.commit("GET_NOTICIAS", {
              data: response.response.news,
              dataSingle: response.response.newsDealer,
              content: response.response.newsContent,
            });
            this.commit("GET_INSTALACOES_CONTENT", {
              data: response.response.services,
              content: response.response.installationsContent,
            });
            this.commit("GET_LEGALPAGES", response.response.legalPagesContent);
            this.commit("GET_FORMPAGES", response.response.formsContent);

            this.commit("form/UPDATE_STATE", {
              formSuccess: response.response?.formSuccess || {},
              formError: response.response?.formError || {},
            });

            /** run prelogin, as dealerInfo.companyId is available */
            this.dispatch("user/prelogin");
          } else {
            console.log("Resultados não encontrados");
          }
        }
      });
      /* await usedInfoLoad().then((response) => {
        this.commit("GET_USADOS", {
          usedCars: response.data.searchResult,
        });
      });
            await newInfoLoad().then((response) => {
        this.commit("GET_NOVOS", {
          data: response.data,
        });
      }); */
      await loadInstalations().then((response) => {
        this.commit("GET_INSTALACOES", {
          installations: response.data,
        });
      });
    },
    async getCar({ commit, state }, values) {
      state.isLoading = true;
      await loadCarByVin(values).then((response) => {
        if (response) {
          if (response.count == 1) {
            commit("GET_CAR", response.data);
            state.isLoading = false;
          } else {
            commit("GET_CAR", {});
            state.isLoading = false;
          }
        }
      });
    },
    simulateFinance({ commit }, values) {
      simulateFinance(values).then((response) => {
        if (response) {
          commit("SIMULATOR", response.data);
        }
      });
    },
    async getNewFilters({ commit }, values) {
      if (values.condition == 1) {
        await getFiltersNew({
          condition: [values.condition],
          ...values.filters,
        }).then((response) => {
          commit("SET_FILTERS_NEW", {
            data: response.data,
          });
        });
      } else {
        await getFiltersUsed({
          condition: [values.condition],
          ...values.filters,
        }).then((response) => {
          commit("SET_FILTERS_USED", {
            data: response.data,
          });
        });
      }
    },
    getSingleUsedCarInfo({ commit, state }, plate) {
      state.isLoading = true;
      loadCarByPlate(plate).then((response) => {
        if (response) {
          if (response.code == 0) {
            const versionCode = response.carInfo.versionCode;
            const comercialModelCode = response.carInfo.comercialModelCode;
            loadCarInfo(versionCode, comercialModelCode).then((response) => {
              if (response) {
                if (response.code == 0) {
                  const modelId = response.car.model.id;
                  //const idVc = response.car.idVc;
                  commit("GET_SINGLEUSEDCARINFO", modelId);
                  state.isLoading = false;
                }
              }
            });
          }
        }
      });
    },
    async getContratos({ commit, state }, values) {
      state.isLoading = true;
      await loadContracts(values).then((response) => {
        if (response) {
          if (response.code == 1) {
            commit("GET_CONTRATOS", response.response.data);
            state.isLoading = false;
          }
        }
      });
    },
    async getSearchedUsed({ commit }, values) {
      await filterCar(values).then((response) => {
        if (response) {
          commit("SET_USADOS", {
            data: response.data,
            count: response.count,
            isShowMore: response.pagination.totalResults > 12,
          });
          commit("ADD_COUNTER");
        }
      });
    },

    async getSearchedNew({ commit }, values) {
      await filterCarNew(values).then((response) => {
        if (response) {
          commit("SET_NOVOS", {
            data: response.data,
            count: response.count,
            isShowMore: response.pagination.totalResults > 12,
          });
          commit("ADD_COUNTER");
        }
      });
    },
    resetState({ commit }, field) {
      commit("RESET_STATE", field);
    },
  },
  modules: {
    user,
    form,
  },
});
