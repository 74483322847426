<template>
  <div id="cookiesModal">
    <div class="modal">
      <h2>COOKIES NO WEBSITE TOYOTA</h2>
      <p>
        Os cookies são uma das razões pelas quais conseguimos proporcionar uma
        experiência incrível no nosso site. No entanto, respeitamos totalmente o
        seu direito de escolher que cookies podemos usar.
      </p>
      <ButtonCTA
        btnLabel="Sim, concordo"
        @action="acceptAllCookies()"
        btnClass="primary-btn full-width-btn "
      />
      <Button
        btnClass="primary-btn full-width-btn "
        btnLink="/politica-cookies"
        btnLabel="Não, voltar às definições de cookies"
      />
    </div>
  </div>
</template>

<script>
import ButtonCTA from "@/components/Buttons/ButtonCTA.vue";
import Button from "@/components/Buttons/Button.vue";

import * as COOKIES from "@/functions/cookies/cookies.js";

export default {
  name: "CookiesModal",
  components: {
    Button,
    ButtonCTA,
  },
  methods: {
    acceptAllCookies() {
      COOKIES.COOKIES_FUNCTIONS.setCookie("_rg_settings", "S,S,S");
      COOKIES.COOKIES_FUNCTIONS.setCookie("_rg_modal", "N");
      this.$store.dispatch("resetState", {
        state: "cookieModal",
        value: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_modal"),
      });
      this.$store.dispatch("resetState", {
        state: "cookieSettings",
        value: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_settings"),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Cookies";
</style>
