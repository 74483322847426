<template>
  <button :class="btnClass + 'btn'" type="button" @click="submit">
    {{ btnLabel }}
  </button>
</template>

<script>
export default {
  name: "ButtonSubmit",
  props: {
    btnClass: { type: String, default: "" },
    btnLabel: { type: String, default: "" },
    id: { type: String, default: "" },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Buttons";
</style>
