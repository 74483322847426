<template>
  <div class="wrapper">
    <HeroHomepage :data="hpData" :dealerData="hpDealerData" />
    <Section>
      <HeadingBlock :data="hpData.textBlock" />
    </Section>
    <Section>
      <UsedCarsSection
        :data="usedCars"
        page="homepage"
        classSection="homepage-container "
      />
    </Section>
    <CTA />
  </div>
</template>

<script>
import CTA from "@/components/CTA/CTA.vue";
import HeroHomepage from "@/components/Heroes/HeroHomepage.vue";
import Section from "@/components/Section/Section.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import UsedCarsSection from "@/components/UsedCars/UsedCarsSection.vue";

import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "Homepage",
  metaInfo() {
    return manageSEO(this.seo, this.seo.general, false, this.dealerName);
  },
  components: {
    CTA,
    HeroHomepage,
    Section,
    HeadingBlock,
    UsedCarsSection,
  },
  created() {
    this.getInitUsed();
  },
  data() {
    return {
      hpData: this.$store.state.homepage,
      hpDealerData: this.$store.state.homepageDealer,
      dealerName: this.$store.state.dealerInfo.dealerName,
    };
  },

  computed: {
    usedCars() {
      return this.$store.getters.getNewUsedCarsArray.slice(0, 2);
    },
    seo() {
      return this.$store.state.homepage.seo;
    },
  },
  methods: {
    replaceBOVariable(text, variable, info) {
      return text.replaceAll(variable, info);
    },
    getInitUsed() {
      this.$store.commit("SET_LOADING", true);
      this.$store
        .dispatch("getSearchedUsed", {
          filters: this.$store.state.appliedFilters,
          order: {},
          elements: 2,
        })
        .then(() => {
          this.$store.commit("SET_LOADING", false);
        });
    },
  },
};
</script>
