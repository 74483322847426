var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav"},[_c('div',{staticClass:"container row"},[_c('router-link',{class:_vm.currentPage == 'Novos' ? 'selected' : '',attrs:{"to":"/novos"},nativeOn:{"click":function($event){return _vm.openMenu.apply(null, arguments)}}},[_c('span',[_vm._v("Novos")])]),_c('router-link',{class:_vm.currentPage == 'Usados' || _vm.currentPage == 'UsadosSingle'
          ? 'selected'
          : '',attrs:{"to":"/usados"},nativeOn:{"click":function($event){return _vm.openMenu.apply(null, arguments)}}},[_c('span',[_vm._v("Usados")])]),_c('router-link',{class:_vm.currentPage == 'AposVenda' ? 'selected' : '',attrs:{"to":"/apos-venda"},nativeOn:{"click":function($event){return _vm.openMenu.apply(null, arguments)}}},[_c('span',[_vm._v("Após-venda")])]),_c('router-link',{class:_vm.currentPage == 'Noticias' || _vm.currentPage == 'NoticiasSingle'
          ? 'selected'
          : '',attrs:{"to":"/noticias"},nativeOn:{"click":function($event){return _vm.openMenu.apply(null, arguments)}}},[_c('span',[_vm._v("Notícias")])]),_c('router-link',{class:_vm.currentPage == 'Campanhas' || _vm.currentPage == 'CampanhasSingle'
          ? 'selected'
          : '',attrs:{"to":"/campanhas"},nativeOn:{"click":function($event){return _vm.openMenu.apply(null, arguments)}}},[_c('span',[_vm._v("Campanhas")])]),_c('router-link',{class:_vm.currentPage == 'Instalacoes' || _vm.currentPage == 'InstalacoesSingle'
          ? 'selected'
          : '',attrs:{"to":"/instalacoes"},nativeOn:{"click":function($event){return _vm.openMenu.apply(null, arguments)}}},[_c('span',[_vm._v("Instalações")])]),(_vm.isLogedOut && !_vm.checkIsCaetanoGoAuth)?[_c('p',{class:{ auth: true },on:{"click":function($event){return _vm.toggleModal(true)}}},[_c('span',[_vm._v("Login")])]),_c('p',{class:{ auth: true },on:{"click":function($event){return _vm.toggleModal(false)}}},[_c('span',[_vm._v("Registo")])])]:_vm._e(),(!_vm.isLogedOut)?[_c('router-link',{class:{ autoLeft: true, selected: _vm.isProfilePage },attrs:{"to":/user/ + _vm.userName}},[_c('span',[_vm._v("Área Cliente")])])]:_vm._e(),(_vm.checkIsCaetanoGoAuth)?_c('div',{class:{ auth: true },attrs:{"data-idgo-widget":""}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }