<template>
  <div class="nav">
    <div class="container row">
      <router-link
        :class="currentPage == 'Novos' ? 'selected' : ''"
        to="/novos"
        @click.native="openMenu"
      >
        <span>Novos</span>
      </router-link>
      <router-link
        :class="
          currentPage == 'Usados' || currentPage == 'UsadosSingle'
            ? 'selected'
            : ''
        "
        to="/usados"
        @click.native="openMenu"
      >
        <span>Usados</span>
      </router-link>
      <router-link
        :class="currentPage == 'AposVenda' ? 'selected' : ''"
        to="/apos-venda"
        @click.native="openMenu"
      >
        <span>Após-venda</span>
      </router-link>
      <router-link
        :class="
          currentPage == 'Noticias' || currentPage == 'NoticiasSingle'
            ? 'selected'
            : ''
        "
        to="/noticias"
        @click.native="openMenu"
      >
        <span>Notícias</span>
      </router-link>
      <router-link
        :class="
          currentPage == 'Campanhas' || currentPage == 'CampanhasSingle'
            ? 'selected'
            : ''
        "
        to="/campanhas"
        @click.native="openMenu"
      >
        <span>Campanhas</span>
      </router-link>
      <router-link
        :class="
          currentPage == 'Instalacoes' || currentPage == 'InstalacoesSingle'
            ? 'selected'
            : ''
        "
        to="/instalacoes"
        @click.native="openMenu"
      >
        <span>Instalações</span>
      </router-link>

      <template v-if="isLogedOut && !checkIsCaetanoGoAuth">
        <p :class="{ auth: true }" @click="toggleModal(true)">
          <span>Login</span>
        </p>

        <p :class="{ auth: true }" @click="toggleModal(false)">
          <span>Registo</span>
        </p>
      </template>

      <template v-if="!isLogedOut">
        <router-link
          :class="{ autoLeft: true, selected: isProfilePage }"
          :to="/user/ + userName"
        >
          <span>Área Cliente</span>
        </router-link>
      </template>

      <div
        v-if="checkIsCaetanoGoAuth"
        data-idgo-widget
        :class="{ auth: true }"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "Navbar",
  mounted() {
    const queryAction = this.$route.query?.action;

    this.setupCaetanoGoScript();

    this.$root.$on("login", () => {
      this.toggleModal(true);
    });
    this.$root.$on("register", () => {
      this.toggleModal(false);
    });

    /** handle redirect after login */
    if (queryAction == "login") {
      /** redirect */
      const path = sessionStorage.getItem("routePath");
      path && this.$router.push(path);
    } else if (queryAction == "registration") {
      /** redirect */
      this.$router.push("/post-reg");
    }
  },
  computed: {
    ...mapState(["dealerInfo", "user"]),
    ...mapGetters({
      isLogedOut: "user/checkUserLogedOut",
      userName: "user/getUserName",
      checkIsCaetanoGoAuth: "user/useCaetanoGoLogin",
    }),
    currentPage() {
      return this.$route.name;
    },
    isProfilePage() {
      const userChildRoutes = this.$router.options.routes.find(
        (r) => r.path == "/user/:id"
      )?.children;
      const bool = userChildRoutes
        .map((p) => p.name)
        .find((el) => el == this.currentPage);
      return bool ? bool : false;
    },
  },
  methods: {
    ...mapMutations({
      updateUserState: "user/UPDATE_STATE",
    }),
    openMenu() {
      this.$emit("openMenu");
    },
    toggleModal(viewType) {
      if (!this.checkIsCaetanoGoAuth) {
        this.updateUserState({ modalOpen: true, modalView: viewType });
        return;
      }

      viewType
        ? document
            .querySelectorAll("[data-idgo-widget]")[0]
            .ifc.openPopup("login")
        : document
            .querySelectorAll("[data-idgo-widget]")[0]
            .ifc.openPopup("registration");
    },
    setupCaetanoGoScript() {
      if (!this.checkIsCaetanoGoAuth) return;

      const script = document.createElement("script");
      script.async = true;
      script.setAttribute("src", this.user.script);
      script.setAttribute("type", "text/javascript");
      document.body.appendChild(script);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Header";
</style>

<style lang="scss">
@import "/Widget";
</style>

<style lang="scss">
.idgo-widget .idgo-wrapper .options .user,
.idgo-widget .idgo-wrapper .options .user span {
  margin-top: 0;
}
</style>
