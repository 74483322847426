<template>
  <section :id="id" :class="classSection + 'section'">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "Section",
  props: {
    id: { type: String, default: "" },
    classSection: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
@import "/Section";
</style>
