<template>
  <div class="top-menu">
    <div class="container row">
      <router-link to="/" class="dealer-info row">
        <img
          v-if="isLexus"
          class="lexus-logo"
          src="@/assets/lexus-logo-light.png"
        />
        <img v-else class="toyota-logo" src="@/assets/toyota-white.svg" />
        <p class="header-title" to="/">{{ dealerName }}</p>
      </router-link>
      <div v-if="isMaintenance != 'true'" class="header-buttons row">
        <div
          v-if="!user.id"
          @click="openPop"
          style="cursor: pointer"
          class="header-cta row"
        >
          <div class="heart-logo"><Heart /></div>
          <div class="cta-label elm-desktop">Favoritos</div>
        </div>
        <router-link v-else to="/favoritos" class="header-cta row">
          <div class="heart-logo"><Heart /></div>
          <div class="cta-label elm-desktop">Favoritos</div>
        </router-link>
        <router-link to="/proposta" class="header-cta row">
          <img src="@/assets/phone.svg" />
          <div class="cta-label elm-desktop">Contactem-me</div>
        </router-link>
        <div class="hamburguer elm-tablet" @click="openMenu">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heart from "@/assets/Heart.vue";
import { mapGetters } from "vuex";
export default {
  name: "TopMenu",
  components: {
    Heart,
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
      isLexus: this.$store.state.isLexus,
    };
  },
  computed: {
    ...mapGetters("user", {
      user: "getUserData",
    }),
  },
  methods: {
    openMenu() {
      this.$emit("openMenu");
    },
    openPop() {
      this.$root.$emit("login");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Header";
.lexus-logo {
  max-width: 150px;
}
.heart-logo {
  width: 16px;
  height: 16px;
  fill: white;
  display: flex;
  align-items: center;
  margin-bottom: auto;
  margin-top: auto;
}
</style>
