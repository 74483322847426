import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "../views/Homepage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Homepage",
    component: Homepage,
  },
  {
    path: "/novos",
    name: "Novos",
    component: () => import("../views/Range.vue"),
  },
  {
    path: "/novos/:vin",
    name: "NovosSingle",
    component: () => import("../views/RangeSingle.vue"),
  },
  {
    path: "/usados",
    name: "Usados",
    component: () => import("../views/UsedCars.vue"),
  },
  {
    path: "/usados/:vin",
    name: "UsadosSingle",
    component: () => import("../views/UsedCarsSingle.vue"),
  },
  {
    path: "/noticias",
    name: "Noticias",
    component: () => import("../views/News.vue"),
  },
  {
    path: "/noticias/:slug",
    name: "NoticiasSingle",
    component: () => import("../views/NewsSingle.vue"),
  },
  {
    path: "/campanhas",
    name: "Campanhas",
    component: () => import("../views/Campaigns.vue"),
  },
  {
    path: "/campanhas/:slug",
    name: "CampanhasSingle",
    component: () => import("../views/CampaignsSingle.vue"),
  },
  {
    path: "/instalacoes",
    name: "Instalacoes",
    component: () => import("../views/Instalacoes.vue"),
  },
  {
    path: "/instalacoes/:id",
    name: "InstalacoesSingle",
    component: () => import("../views/InstalacoesSingle.vue"),
  },
  {
    path: "/apos-venda",
    name: "AposVenda",
    component: () => import("../views/AposVenda.vue"),
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/testdrive",
    name: "Testdrive",
    component: () => import("../views/TestDrive.vue"),
  },
  {
    path: "/proposta",
    name: "Proposal",
    component: () => import("../views/Proposal.vue"),
  },
  {
    path: "/aviso-legal",
    name: "Legal",
    component: () => import("../views/Legal.vue"),
  },
  {
    path: "/politica-privacidade",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/politica-cookies",
    name: "CookiesPolicy",
    component: () => import("../views/CookiesPolicy.vue"),
  },
  {
    path: "/confirmacao",
    name: "RedirOk",
    component: () => import("../views/RedirOk.vue"),
  },
  {
    path: "/erro",
    name: "RedirNOk",
    component: () => import("../views/RedirNOk.vue"),
  },
  {
    path: "/test",
    name: "TestPage",
    component: () => import("../views/TestPage.vue"),
  },
  {
    path: "/retake/:condition",
    name: "Retake",
    component: () => import("../views/Retake.vue"),
  },
  {
    path: "/user/:id",
    component: () => import("../views/UserPage.vue"),
    children: [
      {
        path: "",
        name: "Profile",
        component: () => import("../components/Header/Children/Profile.vue"),
        props: {
          name: false,
        },
      },
      {
        path: "retomas",
        name: "MyRetake",
        component: () => import("../components/Header/Children/RRlst.vue"),
        props: {
          name: "Minhas retomas",
        },
      },
      {
        path: "reservas",
        name: "Reservation",
        component: () => import("../components/Header/Children/RRlst.vue"),
        props: {
          name: "Minhas reservas",
        },
      },
      {
        path: "reservas/:model",
        name: "ReservationDetails",
        component: () =>
          import("../components/Header/Children/ReservationDetails.vue"),
        props: {
          name: false,
        },
      },
    ],
  },
  {
    path: "/recuperar-password",
    name: "RecoverPassword",
    component: () => import("../views/RecoverPasswordPage.vue"),
  },
  {
    path: "/favoritos",
    name: "Favorites",
    component: () => import("../views/Favorites.vue"),
  },
  {
    path: "/post-reg",
    name: "PostRegistration",
    component: () => import("../views/PostRegistration.vue"),
  },
  {
    path: "/reserva",
    name: "ReservationRedirect",
    props: true,
    component: () => import("../views/ReservationRedirect.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("toyota").scrollIntoView();
  },
});

export default router;
