<template>
  <a
    v-if="externalLink"
    :href="btnLink"
    :class="btnClass + 'btn'"
    target="_blank"
    >{{ btnLabel }}</a
  >
  <a
    v-else-if="anchor"
    :href="btnLink"
    :class="btnClass + 'btn'"
    @click="action"
    >{{ btnLabel }}</a
  >
  <router-link
    v-else
    :to="{ name: btnName, path: btnLink, params: btnParams }"
    :class="btnClass + 'btn'"
    >{{ btnLabel }}</router-link
  >
</template>

<script>
export default {
  name: "Button",
  props: {
    btnClass: { type: String, default: "" },
    btnLabel: { type: String, default: "" },
    btnLink: { type: String, default: "" },
    btnName: { type: String, default: "" },
    btnParams: { type: Object },
    externalLink: { type: Boolean, default: false },
    anchor: { type: Boolean, default: false },
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Buttons";
</style>
