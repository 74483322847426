<template>
  <footer class="red-top-left">
    <div class="branding row">
      <img v-if="!isLexus" src="@/assets/toyota-dark.svg" />
      <img v-else src="@/assets/lexus-logo-light.png" />
      <img :src="dealerLogo" />
    </div>
    <div class="legal row">
      <div class="copyright">
        Copyright © <span v-if="isLexus">Lexus</span
        ><span v-else>Toyota</span> Portugal
      </div>
      <div v-if="isMaintenance != 'true'" class="links row">
        <a
          :href="
            isLexus
              ? 'https://www.lexus.pt/legal/politica-de-privacidade'
              : 'https://www.toyota.pt/legal/politica-de-privacidade'
          "
          >Termos e Condições</a
        >
        <router-link to="/aviso-legal">Aviso Legal</router-link>
        <router-link to="/politica-privacidade"
          >Política de Privacidade</router-link
        >
        <router-link to="/politica-cookies">Política de Cookies</router-link>
        <a :href="linkComplaint.url" target="_blank">Livro de Reclamações</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      dealerLogo: this.$store.state.dealerInfo.icon,
      isLexus: this.$store.state.isLexus,
      linkComplaint: this.$store.state.linkComplaint,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/Footer";
</style>
