<template>
  <div :class="btnClass + 'btn'" @click="action" v-if="!btnDisabled">
    {{ btnLabel }}
  </div>
  <div :class="btnClass + 'btn'" v-else>{{ btnLabel }}</div>
</template>

<script>
export default {
  name: "ButtonCTA",
  props: {
    btnClass: { type: String, default: "" },
    btnLabel: { type: String, default: "" },
    btnDisabled: { type: Boolean, default: false },
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Buttons";
</style>
