<template>
  <div>
    <div
      data-type="checkbox"
      class="form__group--checkbox required"
      :data-label="data.label"
      :data-errormessage="data.errormessage"
      :id="id + '-' + data.name"
    >
      <template v-for="(option, index) in data.options">
        <input
          type="checkbox"
          :name="data.name"
          :id="data.name + '-' + option.value"
          :value="option.value"
          :key="'input-' + index"
          class="form__control"
          @change="emitCheckboxValue($event, index)"
        />
        <label :for="data.name + '-' + option.value" :key="index">
          {{ option.label }}
        </label>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxField",
  props: {
    data: { type: Object },
    id: { type: String },
  },
  methods: {
    emitCheckboxValue(e) {
      this.$emit("update:value", e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
