<template>
  <router-link :to="link" class="card-cta">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "CardCTA",
  props: {
    link: { type: String, default: "/" },
  },
};
</script>

<style lang="scss" scoped>
@import "/Card";
</style>
