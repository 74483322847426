export function checkElement(id, type) {
  if (type == "checkbox" || type == "radio") {
    const checked = document.querySelector('[name="' + id + '"]:checked');
    if (checked) {
      return true;
    }
    return false;
  } else {
    let domElement = document.querySelector(id);

    if (domElement) {
      const value = domElement.value;
      if (value == "") {
        return false;
      } else {
        if (type == "phone") {
          return __isValidPhone(value);
        } else if (type == "email") {
          return __isValidEmail(value);
        } else if (type == "nif") {
          return __isValidNIF(value);
        } else if (type == "postalcode") {
          return __isValidPostlCode(value);
        }
        return true;
      }
    }
    return true;
  }
}

function __isValidPhone(value) {
  const pattern = "^9[1236]\\d{7}$";
  return mathRegex(value, pattern);
}

function __isValidPostlCode(value) {
  const pattern = "^\\d{4}-\\d{3}$";
  return mathRegex(value, pattern);
}

function __isValidEmail(value) {
  const pattern =
    "^(?!\\.)(?!.*?\\.\\.)(?!.*?\\.@)[A-Za-z0-9!#\\.$%&'*+/=?^_`{|}~-]+@(?!-)[A-Za-z0-9-\\.]+\\.[A-Za-z]{2,5}$";
  return mathRegex(value, pattern);
}

function __isValidNIF(value) {
  if (
    !["1", "2", "3", "5", "6", "8"].includes(value.substr(0, 1)) &&
    !["45", "70", "71", "72", "77", "79", "90", "91", "98", "99"].includes(
      value.substr(0, 2)
    )
  )
    return false;

  let total =
    value[0] * 9 +
    value[1] * 8 +
    value[2] * 7 +
    value[3] * 6 +
    value[4] * 5 +
    value[5] * 4 +
    value[6] * 3 +
    value[7] * 2;

  let modulo11 = total - parseInt(total / 11) * 11;
  let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

  return value[8] == comparador;
}

function mathRegex(value, pattern) {
  if (value.match(pattern)) {
    return true;
  } else {
    return false;
  }
}

export function checkPlate(value) {
  // replace for all
  value = value.replace(/\s/g, "");

  return checkPlatePT(value);
}

function checkPlatePT(value) {
  //AA00AA | AA0000 | 00AA00 | 0000AA
  // '^(([A-Z]{2}[0-9]{2}([0-9]{2}|[A-Z]{2}))|([0-9]{2}([0-9]{2}[A-Z]{2}|[A-Z]{2}[0-9]{2})))$';
  //AA-00-AA | AA-00-00 | 00-AA-00 | 00-00-AA

  // eslint-disable-next-line no-useless-escape, prettier/prettier
  const plateRegex = /^(?:[A-Z]{2}\d{2}[A-Z]{2}|[A-Z]{2}\d{4}|(?:\d{2}[A-Z]{2}\d{2}|\d{4}[A-Z]{2}))$/;
  if (plateRegex.test(value.toUpperCase())) {
    return true;
  } else {
    return false;
  }
}

export default {
  __isValidEmail,
};
