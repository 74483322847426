import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import router from "./router/indexMaintenance";
import store from "./store";
import VueMeta from "vue-meta";
import * as VueGoogleMaps from "vue2-google-maps";
import * as geolib from "geolib";

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA7p9dsEBjH7LhKjpCpgAlPMmLFDrafII8",
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

Vue.use(geolib);

Vue.use(VueMeta);

//variable for maintenance
Vue.prototype.isMaintenance = "false";

new Vue({
  router,
  store,
  VueGoogleMaps,
  render: (h) => h(App),
}).$mount("#app");
