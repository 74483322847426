<template>
  <div :class="wrapperClass + 'btn-wrapper'">
    <Button
      v-for="(data, index) in data"
      :key="index"
      :btnClass="btnClass"
      :btnLink="data.buttonLink"
      :btnLabel="data.buttonLabel"
      :btnName="data.buttonName"
      :btnParams="data.buttonParams"
      :externalLink="data.externalLink"
      :anchor="data.anchor"
      @action="action(index)"
    />
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
export default {
  name: "ButtonsWrapper",
  components: {
    Button,
  },
  props: {
    btnClass: { type: String, default: "" },
    data: { type: [Array, Object] },
    wrapperClass: { type: String, default: "" },
  },
  methods: {
    action(event) {
      this.$emit("action", event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Buttons";
</style>
