<template>
  <div class="fluid-element">
    <div :class="classHero + 'hero row'">
      <div class="hero-left" v-if="leftContent">
        <slot name="heroLeft"></slot>
      </div>
      <div :class="redClass">
        <div class="hero-image" v-if="image">
          <slot name="heroImageText"></slot>
          <img
            :class="classImage"
            :src="image.imageUrl"
            :alt="image.imageAlt"
            :text="image.imageTitle"
          />
        </div>
        <div class="hero-right" v-else>
          <slot name="heroRight"></slot>
        </div>
        <slot name="slider"></slot>
      </div>
      <div class="wrapper">
        <slot name="afterSlider"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  props: {
    image: { type: Object },
    classHero: { type: String, default: "" },
    redClass: { type: String, default: "" },
    classImage: { type: String, default: "img-cover " },
  },
  computed: {
    leftContent() {
      return this.$slots.heroLeft;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Heroes";
</style>
