<template>
  <div class="modalBox">
    <div class="blend"></div>
    <div class="content center">
      <p class="content__header">Validação de conta</p>
      <div class="form form--validate">
        <div class="form__group">
          <div class="form__field">
            <input
              :style="inputStyle"
              type="text"
              maxlength="6"
              @keypress="isNumber($event)"
              @change="code = $event.target.value"
              placeholder="Codigo Validação"
              class="form__control required inputText"
            />
            <p
              :class="user.ok ? 'content__msg' : 'content__error'"
              v-show="user.erorMsg"
            >
              {{ user.erorMsg }}
            </p>
          </div>
        </div>
      </div>

      <div class="content__btn--wrap">
        <ButtonSubmit
          btnClass="primary-btn "
          btnLabel="Submeter"
          @submit="submitCode(code)"
        />
        <p
          class="content__newCode"
          @click="$store.dispatch('user/resendActivationCode')"
        >
          Desejo receber novo codigo
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import ButtonSubmit from "@/components/Buttons/ButtonSubmit.vue";
export default {
  name: "ValidateAccount",
  components: {
    ButtonSubmit,
  },
  data() {
    return {
      inputStyle: {
        "text-align": "center",
        padding: "1rem 2rem",
        "font-size": "1.25rem",
      },
      code: 0,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations({
      updateUserState: "user/UPDATE_STATE",
    }),
    isNumber(e) {
      const value = e.target.value;
      const charCode = e.charCode;

      if (charCode >= 48 && charCode <= 57 && charCode != 46) return true;

      if (e.charCode == 13 && value != "") this.submitCode(value);

      e.preventDefault();
    },
    submitCode(val) {
      this.updateUserState({ ok: false, erorMsg: false });
      this.$store.dispatch("user/validateCode", val);
    },
  },
};
</script>
<style lang="scss">
@import "@/components/Forms/Form.scss";
@import "./LoginModal.scss";
</style>
