<template>
  <div class="fluid-element">
    <div class="cta-wrapper">
      <div class="cta-title">
        {{ ctas.ctaTitle ? ctas.ctaTitle : "Próximos Passos" }}
      </div>
      <div class="cta-links row" v-if="ctas.ctas">
        <router-link
          v-for="(cta, index) in ctas.ctas"
          :key="index"
          :to="cta.buttonLink"
          class="link-cta-white"
          >{{ cta.buttonLabel }}</router-link
        >
      </div>
      <div class="cta-links row" v-else-if="isRanged || isUsed">
        <div v-for="(cta, index) in ctas" :key="index">
          <div @click="callAction(cta.buttonLink)" class="link-cta-white">
            {{ cta.buttonLabel }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CTA",
  props: {
    isUsed: { type: Boolean, default: false },
    isRanged: { type: Boolean, default: false },
  },
  data() {
    return {
      ctas: this.isUsed
        ? this.$store.state.usadosSubmenu
        : this.isRanged
        ? this.$store.state.novosSubmenu
        : this.$store.state.ctas,
    };
  },
  methods: {
    callAction(buttonLink) {
      this.$emit(
        "callAction",
        buttonLink == "#testDrive"
          ? "1"
          : buttonLink == "#onlineSelling"
          ? "2"
          : "-1"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/CTA";
</style>
