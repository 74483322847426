<template>
  <div class="card row">
    <div class="inside-card row">
      <div v-if="header" class="card-header">
        <slot name="header"></slot>
      </div>
      <div v-if="content" class="card-content">
        <slot name="content"></slot>
      </div>
      <div v-if="footer" class="card-footer">
        <slot name="footer"></slot>
      </div>
    </div>
    <slot name="outisde-card"></slot>
  </div>
</template>

<script>
export default {
  name: "Card",
  computed: {
    header() {
      return this.$slots.header;
    },
    content() {
      return this.$slots.content;
    },
    footer() {
      return this.$slots.footer;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Card";
</style>
