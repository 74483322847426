var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modalBox"},[_c('div',{staticClass:"blend",on:{"click":function($event){return _vm.closeModal()}}}),_c('div',{staticClass:"content"},[_c('svg',{staticClass:"content__close",attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){return _vm.updateUserState({ modalOpen: false, erorMsg: false })}}},[_c('path',{attrs:{"d":"M18 1.5L0.999999 18.5","stroke":"#6C7073","stroke-width":"2"}}),_c('path',{attrs:{"d":"M1.5 1L18.5 18","stroke":"#6C7073","stroke-width":"2"}})]),_c('p',{staticClass:"content__header"},[_vm._v(_vm._s(_vm.getLabel))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.erorMsg),expression:"user.erorMsg"}],class:_vm.user.ok ? 'content__msg' : 'content__error'},[_vm._v(" "+_vm._s(_vm.user.erorMsg)+" ")]),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form__group"},[_vm._l((_vm.getFormFields),function(field){return _c('div',{key:field.id,class:[field.class, field.errMsg ? 'form--error' : '']},[_c('InputField',{attrs:{"data":{
              type: field.type,
              name: field.name,
              label: field.label,
            },"id":"a1","value":_vm.formValues[field.name]},on:{"update:value":function($event){return _vm.$set(_vm.formValues, field.name, $event)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(field.errMsg),expression:"field.errMsg"}],staticClass:"form__message"},[_vm._v(" "+_vm._s(field.errMsg)+" ")])],1)}),(!_vm.recoverView && _vm.user.modalView)?_c('div',{staticClass:"form__field"},[_c('CheckboxField',{attrs:{"id":"session","value":_vm.formValues.saveSession,"data":{
              label: 'Lembrar-me',
              name: 'saveSession',
              options: [
                {
                  label: 'Lembrar-me',
                  name: 'saveSession',
                  value: '',
                } ],
            }},on:{"update:value":function($event){return _vm.$set(_vm.formValues, "saveSession", $event)}}})],1):_vm._e()],2)]),(!_vm.recoverView && _vm.user.modalView)?_c('p',{on:{"click":function($event){_vm.recoverView = !_vm.recoverView}}},[_c('span',[_vm._v("Esqueci-me da palavra-passe")])]):_vm._e(),(_vm.user.modalView && !_vm.recoverView)?_c('p',[_vm._v(" Ainda não tem conta? "),_c('span',{on:{"click":function($event){return _vm.updateUserState({ modalView: false, erorMsg: false })}}},[_vm._v("Registe-se aqui")])]):_vm._e(),_c('div',{staticClass:"content__btn-wrap"},[_c('ButtonSubmit',{attrs:{"btnClass":"primary-btn ","btnLabel":_vm.getLabel},on:{"submit":function($event){return _vm.handleAuthSubmit()}}}),(!_vm.user.modalView || _vm.recoverView)?_c('p',[_vm._v(" Voltar ao "),_c('span',{on:{"click":function () {
              _vm.recoverView = false;
              _vm.updateUserState({ modalView: true, erorMsg: false });
            }}},[_vm._v("Login")])]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }