<template>
  <div class="modalBox">
    <div class="blend" @click="closeModal()"></div>
    <div class="content">
      <svg
        class="content__close"
        @click="updateUserState({ modalOpen: false, erorMsg: false })"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18 1.5L0.999999 18.5" stroke="#6C7073" stroke-width="2" />
        <path d="M1.5 1L18.5 18" stroke="#6C7073" stroke-width="2" />
      </svg>

      <p class="content__header">{{ getLabel }}</p>
      <p
        :class="user.ok ? 'content__msg' : 'content__error'"
        v-show="user.erorMsg"
      >
        {{ user.erorMsg }}
      </p>

      <div class="form">
        <div class="form__group">
          <div
            v-for="field in getFormFields"
            :key="field.id"
            :class="[field.class, field.errMsg ? 'form--error' : '']"
          >
            <InputField
              :data="{
                type: field.type,
                name: field.name,
                label: field.label,
              }"
              id="a1"
              :value.sync="formValues[field.name]"
            />
            <span v-show="field.errMsg" class="form__message">
              {{ field.errMsg }}
            </span>
          </div>

          <div v-if="!recoverView && user.modalView" class="form__field">
            <CheckboxField
              id="session"
              :value.sync="formValues.saveSession"
              :data="{
                label: 'Lembrar-me',
                name: 'saveSession',
                options: [
                  {
                    label: 'Lembrar-me',
                    name: 'saveSession',
                    value: '',
                  },
                ],
              }"
            />
          </div>
        </div>
      </div>

      <p
        v-if="!recoverView && user.modalView"
        @click="recoverView = !recoverView"
      >
        <span>Esqueci-me da palavra-passe</span>
      </p>

      <p v-if="user.modalView && !recoverView">
        Ainda não tem conta?
        <span @click="updateUserState({ modalView: false, erorMsg: false })"
          >Registe-se aqui</span
        >
      </p>

      <div class="content__btn-wrap">
        <ButtonSubmit
          btnClass="primary-btn "
          :btnLabel="getLabel"
          @submit="handleAuthSubmit()"
        />

        <p v-if="!user.modalView || recoverView">
          Voltar ao
          <span
            @click="
              () => {
                recoverView = false;
                updateUserState({ modalView: true, erorMsg: false });
              }
            "
            >Login</span
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonSubmit from "@/components/Buttons/ButtonSubmit.vue";
import { mapMutations, mapState } from "vuex";
import Validate from "@/functions/validation";

import InputField from "@/components/Forms/InputField.vue";
import CheckboxField from "@/components/Forms/CheckboxField.vue";

export default {
  name: "LoginModal",
  components: {
    ButtonSubmit,
    InputField,
    CheckboxField,
  },
  data() {
    return {
      recoverView: false,
      checkErrFields: false,
      formValues: {
        name: "",
        email: "",
        password: "",
        reapeatPassword: "",
        saveSession: "",
      },
      acceptLabel: "*Aceito a política de privacidade de dados.",
      disclaimerLabel:
        "Autorizo o tratamento dos meus dados pessoais para marketing de produtos e serviços comercializados pela CAETANO AUTO, S.A e sociedades participadas da Salvador Caetano Auto, SGPS, S.A., e pelas sociedades importadoras e/ou fabricantes da marca do veículo que seja adquirido, objeto de prestação de serviços, que foi experimentado ou em que mostrei interesse.",
    };
  },

  watch: {
    recoverView: function (val) {
      if (!val) {
        Object.keys(this.formValues).forEach((k) => (this.formValues[k] = ""));
      }
      this.updateUserState({ erorMsg: false });
      this.checkErrFields = false;
    },
  },

  beforeDestroy() {
    this.$route.name == "RecoverPassword" && this.$router.push("/");
  },

  computed: {
    ...mapState(["user"]),

    getLabel() {
      return this.recoverView
        ? "Recuperar Password"
        : this.user.modalView
        ? "Login"
        : "Registo";
    },
    getFormFields() {
      const isRecoveringPw = this.recoverView;
      const checkErrFields = this.checkErrFields;
      const formValues = this.formValues;

      const arr = [
        {
          id: 0,
          name: "name",
          type: "text",
          label: "Nome",
          inputClass: "form__control required inputText",
          class:
            !this.user.modalView && !isRecoveringPw ? "form__field" : "hide",
          errMsg:
            checkErrFields && formValues.name == ""
              ? "Campo obrigatório"
              : false,
        },
        {
          id: 1,
          name: "email",
          type: "text",
          label: "Email",
          inputClass: "form__control required inputText",
          class: "form__field",
          errMsg: checkErrFields
            ? formValues.email == ""
              ? "Campo obrigatório"
              : !Validate.__isValidEmail(formValues.email)
              ? "Email invalido"
              : false
            : false,
        },
        {
          id: 2,
          name: "password",
          type: "password",
          label: "Password",
          inputClass: "form__control required inputText",
          class: !isRecoveringPw ? "form__field" : "hide",
          errMsg:
            checkErrFields && formValues.password == ""
              ? "Campo obrigatório"
              : false,
        },
        {
          id: 3,
          name: "reapeatPassword",
          type: "password",
          label: "Confirmar password",
          inputClass: "form__control required inputText",
          class:
            !isRecoveringPw && !this.user.modalView ? "form__field" : "hide",
          errMsg:
            checkErrFields && formValues.reapeatPassword != formValues.password
              ? "As senhas não correspondem"
              : checkErrFields && formValues.reapeatPassword == ""
              ? "Campo obrigatório"
              : false,
        },
      ];

      return arr;
    },
  },
  methods: {
    ...mapMutations({
      updateUserState: "user/UPDATE_STATE",
      closeModal: "user/CLOSE_MODAL",
    }),

    updateFormData(e, key, id) {
      const value = id == 4 ? e.target.checked : e.target.value;
      this.formValues[key] = value;
    },

    handleAuthSubmit() {
      // handle fields errors
      this.checkErrFields = true;
      const obj = this.formValues;

      if (
        !this.recoverView &&
        (obj.password == "" || (obj.name == "" && !this.user.modalView))
      )
        return;

      if (
        !this.user.modalView &&
        !this.recoverView &&
        this.password != this.reapeatPassword
      )
        return;

      const isEmailValid = Validate.__isValidEmail(obj.email);
      if (!isEmailValid) {
        return;
      }

      const _action = this.recoverView
        ? "user/reqPasswordRecover"
        : this.user.modalView
        ? "user/loginUser"
        : "user/registerUser";

      this.$store.dispatch(_action, obj);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/Forms/Form.scss";
@import "./LoginModal.scss";
</style>
