import axios from "axios";
import { generateUUID } from "@/functions/general.js";

import * as endpoints from "../../services/endpoints";

import {
  setFavorites,
  removeFavorites,
  getFavorites,
} from "@/services/functions.js";

import router from "@/router";

const tempSwagger = process.env.VUE_APP_NEW_DOMAIN;

const state = () => ({
  modalOpen: false,
  modalView: true,
  ok: false,
  erorMsg: false,

  useCaetanoGo: [5, 22],
  isCaetanoLogin: false,
  loginToken: null,
  userData: {},

  sessionId: false,
  script: false,
  favorites: [],
  retakes: null,
  reservations: null,
});

const getters = {
  checkUserLogedOut: (state) => (state.loginToken != null ? false : true),
  checkValidationAccount: (state) => state.userData?.userStatus == 0,
  getUserName: (state) => state.userData?.name,

  useCaetanoGoLogin: (state, getters, rootState) => {
    const companyId = parseInt(rootState.dealerInfo.companyId);
    return state.useCaetanoGo.includes(companyId);
  },

  mapReservationLst: (state) => {
    let obj = [];
    if (state.reservations) {
      obj = state.reservations.sort(
        (a, b) =>
          new Date(b.reserveDate).getTime() - new Date(a.reserveDate).getTime()
      );
      return obj;
    }
    return obj;
  },
  mapRetakesLst: (state) => {
    let obj = state.retakes;
    return obj;
  },

  getReservationById: (state) => (id) => {
    let obj = state.reservations;
    return obj ? obj.find((el) => el.id == id) : false;
  },

  getUserData: (state) => {
    return state.userData;
  },
};

const mutations = {
  UPDATE_STATE(state, payload) {
    state = Object.assign(state, payload);
  },
  CLOSE_MODAL(state) {
    state.modalOpen = false;
    state.erorMsg = false;
  },
  SET_FAVORITES(state, payload) {
    state.favorites = payload;
  },
  REMOVE_FAVORITE(state, vin) {
    state.favorites.splice(state.favorites.indexOf(vin), 1);
  },
  ADD_FAVORITE(state, vin) {
    state.favorites.push(vin);
  },
};

const actions = {
  registerUser({ commit, rootState }, obj) {
    const headers = { headers: { companyId: rootState.dealerInfo.companyId } };
    const body = {
      birthdate: "",
      email: obj.email,
      emailMarketing: true,
      favoriteDealership: "",
      favoriteDistrict: "",
      name: obj.name,
      password: obj.password,
      phoneNumber: "",
    };

    axios
      .post(`${tempSwagger}users/register`, body, headers)
      .then((res) => {
        commit("UPDATE_STATE", {
          register: res,
          modalView: true,
          ok: true,
          erorMsg:
            "Registo efetuado com successo. Verifique o email para validar a conta",
        });
      })
      .catch((err) => {
        const data = err.response.data;
        const msg =
          data.statusCode === 409
            ? "O email inserido já existe!"
            : "De momento não é possivel efetuar o registo. Tente mais tarde";
        commit("UPDATE_STATE", {
          ok: false,
          erorMsg: msg,
          modalOpen: true,
          modalView: false,
        });
      });
  },

  loginUser({ commit, rootState, state, dispatch }, obj) {
    const headers = {
      headers: {
        companyId: rootState.dealerInfo.companyId,
        sessionId: state.sessionId,
      },
    };

    axios
      .post(
        `${tempSwagger}users/login`,
        { email: obj.email, password: obj.password, providerId: 1 },
        headers
      )
      .then((res) => {
        const token = res.data.data.token;

        // handle modal
        commit("UPDATE_STATE", {
          loginToken: token,
          modalOpen: false,
          ok: true,
          erorMsg: false,
        });

        /** save token in localStorage if the user chose the option*/
        obj.saveSession && localStorage.setItem("loginToken", token);

        // get user information
        dispatch("getUserData");
        dispatch("getUserFavs");
      })
      .catch(() => {
        // handle modal if req fails
        commit("UPDATE_STATE", {
          modalOpen: true,
          ok: false,
          erorMsg: "Email ou password incorreta",
        });
        localStorage.removeItem("loginToken");
        localStorage.removeItem("loginTokenCaetanoGO");
      });
  },

  loginCaetanoGO({ commit, dispatch, rootState, state }) {
    /** check if user alerdy did login with caetano go */
    const storageToken = localStorage.getItem("loginTokenCaetanoGO");
    if (storageToken) {
      /** save in store token */
      commit("UPDATE_STATE", {
        loginToken: storageToken,
        isCaetanoLogin: true,
      });

      /** get user information */
      dispatch("getUserData");
      dispatch("getUserFavs");
      return;
    }

    /** make request to get login token... */
    const headers = {
      headers: {
        companyId: rootState.dealerInfo.companyId,
        sessionId: state.sessionId,
      },
    };

    axios
      .post(`${tempSwagger}users/login`, { providerId: 5 }, headers)
      .then((res) => {
        const token = res.data.data.token;

        commit("UPDATE_STATE", {
          loginToken: token,
          isCaetanoLogin: true,
        });

        /** save token in localstorage */
        localStorage.setItem("loginTokenCaetanoGO", token);
        dispatch("getUserData");
        dispatch("getUserFavs");
      })
      .catch(() => {
        localStorage.removeItem("loginToken");
        localStorage.removeItem("loginTokenCaetanoGO");
      });
  },

  resendActivationCode({ commit, rootState, state }) {
    const userEmail = state.userData?.userEmail;
    const headers = { headers: { companyId: rootState.dealerInfo.companyId } };
    userEmail &&
      axios
        .post(
          `${tempSwagger}users/activation-code`,
          { email: userEmail },
          headers
        )
        .then(
          (res) =>
            res.data?.data &&
            commit("UPDATE_STATE", {
              erorMsg: "Código de verificação enviado",
              ok: true,
            })
        );
  },

  validateCode({ commit, dispatch, rootState, state }, code) {
    const headers = {
      headers: {
        companyId: rootState.dealerInfo.companyId,
        Authorization: `Bearer ${state.loginToken}`,
      },
    };

    axios
      .put(`${tempSwagger}users/validate`, { confirmationCode: code }, headers)
      .then((res) => {
        res.data.data &&
          commit("UPDATE_STATE", {
            erorMsg: false,
            userData: { userStatus: true },
          });
        dispatch("getUserData");
      })
      .catch(() => {
        commit("UPDATE_STATE", {
          ok: false,
          erorMsg: "Codigo invalido",
        });
      });
  },

  getUserData({ commit, state }) {
    const headers = {
      headers: { Authorization: `Bearer ${state.loginToken}` },
    };

    axios
      .get(`${tempSwagger}users/me`, headers)
      .then((res) => {
        commit("UPDATE_STATE", { userData: res.data.data });
      })
      .catch(() => {
        localStorage.removeItem("loginToken");
        localStorage.removeItem("loginTokenCaetanoGO");
      });
  },

  getUserFavs({ commit, state }) {
    if (state.loginToken) {
      getFavorites(state.loginToken)
        .then((response) => {
          if (response) {
            if (response.data) {
              let vals = [];
              response.data.forEach((element) => {
                vals.push(element.vin);
              });
              commit("SET_FAVORITES", vals);
            }
          }
        })
        .catch(() => {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("loginTokenCaetanoGO");
        });
    }
  },

  prelogin({ commit, dispatch, rootState, getters }) {
    const runCommon = (id, url) => {
      commit("UPDATE_STATE", {
        script: url,
        sessionId: id,
      });

      /** if user login with caetanoGo run action => loginCaetanoGO <= */
      const queryAction = router.currentRoute.query?.action;
      const storageTokenCaetanoGo = localStorage.getItem("loginTokenCaetanoGO");

      if (
        queryAction == "login" ||
        (getters.useCaetanoGoLogin &&
          queryAction != "logout" &&
          storageTokenCaetanoGo)
      )
        dispatch("loginCaetanoGO");
    };

    const sID = window.sessionStorage.getItem("sessionId");
    const url = window.sessionStorage.getItem("scriptUrl");

    if (url != null) {
      runCommon(sID, url);

      return;
    }
    const sessionId = generateUUID();
    const headers = {
      headers: {
        companyId: rootState.dealerInfo.companyId,
        sessionId: sessionId,
      },
    };
    axios
      .get(`${tempSwagger}users/prelogin`, headers)
      .then((res) => {
        const scriptUrl = res.data.data.url;

        runCommon(sessionId, scriptUrl);
        dispatch("loginCaetanoGO");
        window.sessionStorage.setItem("sessionId", sessionId);
        window.sessionStorage.setItem("scriptUrl", scriptUrl);
      })
      .catch(() => {
        localStorage.removeItem("loginToken");
        localStorage.removeItem("loginTokenCaetanoGO");
      });
  },

  /**
   * Get User retakes/reservations
   */

  userRRlst({ commit, state }, bool = true) {
    const path = bool ? "/users/me/retake" : "/users/me/reservations";
    const key = bool ? "retakes" : "reservations";
    const headers = {
      headers: { Authorization: `Bearer ${state.loginToken}` },
    };
    axios
      .get(endpoints.url.newSiteInfo + path, headers)
      .then((res) => {
        commit("UPDATE_STATE", { [key]: res.data.data });
      })
      .catch(() => {});
  },

  logout({ commit, rootState, state }) {
    const headers = {
      headers: {
        companyId: rootState.dealerInfo.companyId,
        Authorization: `Bearer ${state.loginToken}`,
      },
    };

    axios
      .get(`${tempSwagger}users/logout`, headers)
      .then(() => {
        commit("UPDATE_STATE", { userData: {}, loginToken: null });

        /** remove loginToken from localStorage */
        localStorage.removeItem("loginToken");
        localStorage.removeItem("loginTokenCaetanoGO");
      })
      .catch(() => {
        localStorage.removeItem("loginToken");
        localStorage.removeItem("loginTokenCaetanoGO");
      });
  },

  /**
   * Forgot Password
   */
  reqPasswordRecover({ commit, rootState }, obj) {
    const headers = { headers: { companyId: rootState.dealerInfo.companyId } };

    axios
      .post(`${tempSwagger}users/${obj.email}/password/recover`, {}, headers)
      .then((res) => {
        let send = res.data.data;
        send &&
          commit("UPDATE_STATE", {
            ok: true,
            erorMsg: "Email enviado",
          });
      })
      .catch(() => {
        commit("UPDATE_STATE", {
          ok: false,
          erorMsg: "Email não existe",
        });
      });
  },

  updatePasswordRecover({ commit, rootState }, obj) {
    const headers = { headers: { companyId: rootState.dealerInfo.companyId } };
    axios
      .put(
        `${tempSwagger}users/password/recover/update`,
        { code: obj.code, email: obj.email, password: obj.password },
        headers
      )
      .then((res) => {
        const pwUpdated = res.data?.data;
        pwUpdated &&
          commit("UPDATE_STATE", {
            ok: true,
            erorMsg: "Password alterada",
            modalOpen: true,
            modalView: true,
          });
      })
      .catch(() => {
        commit("UPDATE_STATE", {
          ok: false,
          erorMsg: "Ocorreu um erro, tente mais tarde",
        });
      });
  },
  toggleFavorite({ commit, rootState }, data) {
    if (rootState.user.favorites.find((el) => el == data.vin)) {
      removeFavorites(rootState.user.loginToken, {
        company: 0,
        fid: data.fid,
        vin: data.vin,
      }).then(() => {
        commit("REMOVE_FAVORITE", data.vin);
      });
    } else {
      setFavorites(rootState.user.loginToken, {
        company: 0,
        fid: data.fid,
        vin: data.vin,
      }).then(() => {
        commit("ADD_FAVORITE", data.vin);
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
