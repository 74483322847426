<template>
  <Card>
    <template v-slot:header>
      <div
        @click="toogleFavorite"
        :class="isRealFavoite ? 'favorite on' : 'favorite'"
      >
        <div :class="isRealFavoite ? 'favorite-button on' : 'favorite-button'">
          <Heart />
        </div>
      </div>
      <div class="usedCar-image-container">
        <img class="usedCar-image" :src="data.mainImage" />
      </div>
    </template>
    <template v-slot:content>
      <p class="usedCar-title" @click="$router.push('/usados/' + data.vin)">
        {{ data.title }}
      </p>
      <p class="usedCar-subtitle">{{ data.version }}</p>
      <hr class="grey-line" />
      <div class="range-price-row">
        <div class="range-price-details">
          <div class="legal-row">
            <div class="info-legal">
              <img :src="require('@/assets/info.svg')" />
              <p class="info-text" v-html="data.legalInfo"></p>
            </div>
            <p class="range-price-month">{{ data.formattedFee }}</p>
          </div>
          <p class="range-price-taeg">TAEG: {{ data.taeg }}</p>
        </div>
        <div class="range-price-details">
          <div class="range-price-full-pvp">PVP</div>
          <div class="legal-row">
            <div class="info-legal">
              <img :src="require('@/assets/info.svg')" />
              <p class="info-text-right" v-html="data.legalInfo"></p>
            </div>
            <div class="range-price-full">
              {{ data.formattedPrice }}
            </div>
          </div>
        </div>
      </div>
      <div class="range-price-info">
        <p class="range-price-label">
          Entrada inicial:
          <span class="range-price-value">{{ data.initEntrance }}</span>
        </p>
        <p class="range-price-label">
          Prazo:
          <span class="range-price-value">{{ data.nrPayments }} meses</span>
        </p>
        <p class="range-price-label">
          Montante financiado:
          <span class="range-price-value">{{ data.capitalToFinance }}</span>
        </p>
      </div>
      <div class="usedCar-details row">
        <p class="usedCar-detail" v-if="data.kms > 0">{{ data.kms }} kms</p>
        <p class="usedCar-detail" v-if="data.year > 0">{{ data.year }}</p>
        <p v-else></p>
        <p class="usedCar-detail">{{ data.fuelType }}</p>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        btnLabel="Mostrar detalhes"
        :btnLink="'/usados/' + data.vin"
        btnClass="primary-btn full-width-btn "
      />
    </template>
  </Card>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import Card from "@/components/Card/Card.vue";
import Heart from "@/assets/Heart.vue";
import { mapGetters } from "vuex";

export default {
  name: "UsedCars",
  components: {
    Button,
    Card,
    Heart,
  },
  computed: {
    ...mapGetters("user", {
      user: "getUserData",
    }),
    isRealFavoite() {
      return this.isFavorite;
    },
  },
  props: {
    data: { type: Object },
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  methods: {
    toogleFavorite() {
      if (this.user.id) {
        this.$store.dispatch("user/toggleFavorite", {
          vin: this.data.vin,
          fid: this.data.fid,
        });
        this.isFavorite = !this.isFavorite;
      } else {
        this.$root.$emit("login");
      }
    },
  },
  created() {
    if (this.$store.state.user.favorites.find((el) => el == this.data.vin)) {
      this.isFavorite = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "/UsedCars";
</style>
