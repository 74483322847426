<template>
  <Hero
    :image="dealerData.imageHero"
    classHero="hero-30-70 hero-position-center "
    redClass="red-right "
  >
    <template v-slot:heroLeft>
      <h1 class="hero-heading">
        Bem-vindo à <span>{{ dealerName }}!</span>
      </h1>
      <div class="red-left"></div>
      <ButtonsWrapper
        :data="data.subMenu"
        btnClass="animated-btn "
        wrapperClass="column "
      />
    </template>
    <template v-slot:heroImageText>
      <div class="hero-image-info">
        <div class="hero-image-text">
          <p class="hero-image-title">{{ dealerData.titleHero }}</p>
          <p class="hero-image-subtitle">{{ dealerData.textHero }}</p>
        </div>
        <router-link
          :to="dealerData.linkHero.buttonLink"
          class="hero-image-link"
        >
          <img src="@/assets/hp-arrow.svg" />
        </router-link>
      </div>
    </template>
  </Hero>
</template>

<script>
import ButtonsWrapper from "@/components/Buttons/ButtonsWrapper.vue";
import Hero from "@/components/Heroes/Hero.vue";

export default {
  name: "HeroHomepage",
  props: {
    data: { type: Object },
    dealerData: { type: Object },
  },
  components: {
    ButtonsWrapper,
    Hero,
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
    };
  },
  created() {
    this.dealerData.titleHero = this.dealerData.titleHero.replaceAll(
      "{#dealer}",
      this.dealerName
    );
    this.dealerData.textHero = this.dealerData.textHero.replaceAll(
      "{#dealer}",
      this.dealerName
    );
  },
};
</script>

<style lang="scss" scoped>
@import "/Heroes";
</style>
