import { formatPriceNumber } from "@/functions/general.js";

export function getUniques(array, key) {
  const flag = {};
  const unique = [];

  array.forEach((el) => {
    if (!flag[el[key]]) {
      flag[el[key]] = true;
      unique.push(el);
    }
  });
  return unique.sort();
}

export function getAllOidDealers(array) {
  let oidDealers = "";

  array.forEach((installation) => {
    oidDealers += installation.objectId + ",";
  });

  oidDealers = oidDealers.slice(0, -1);

  return oidDealers;
}

export function createInstallationsArray(installations) {
  const newInstallationsArray = [];

  installations.forEach((installation) => {
    const id = installation.objectId;
    const name = installation.designation;
    //const address = installation.address;
    const postalCodeDesig = installation.postalCodeDesig;
    const lat = installation.gpsY;
    const lng = installation.gpsX;
    const services = [];
    installation.services.forEach((el) => {
      services.push(el.objectId);
    });
    const fullName = name + " - " + postalCodeDesig;

    newInstallationsArray.push({
      id: id,
      name: name,
      fullName: fullName,
      lat: lat,
      lng: lng,
      services: services,
    });
  });

  return newInstallationsArray;
}

export function createInstallationSingleArray(
  installations,
  singleInstallationID
) {
  let newSingleInstallationArray = [];
  const singleInstallation = installations.find((installation) => {
    return installation.objectId == singleInstallationID;
  });

  const id = singleInstallation.objectId;
  const idParent = singleInstallation.oidParent;
  const name = singleInstallation.designation;
  const image = singleInstallation.imageInstallation
    ? singleInstallation.imageInstallation.replace(
        "rede.toyota.pt",
        "bd.toyota.pt"
      )
    : "";
  const address =
    singleInstallation.address + ", " + singleInstallation.numberDoor;
  const lat = singleInstallation.gpsY;
  const lng = singleInstallation.gpsX;
  const municipality = singleInstallation.municipality;
  const postalCode =
    singleInstallation.postalCode4 +
    "-" +
    singleInstallation.postalCode3 +
    " " +
    singleInstallation.postalCodeDesig;
  const contacts = [];
  if (singleInstallation.contacts.length > 0) {
    if (singleInstallation.contacts[0].contactPersons.length > 0) {
      if (
        singleInstallation.contacts[0].contactPersons[0].contactList.length > 0
      ) {
        singleInstallation.contacts[0].contactPersons[0].contactList.forEach(
          (el) => {
            const contact = el.designation + " - " + el.value;
            contacts.push(contact);
          }
        );
      }
    }
  }
  const fullName = name + " - " + singleInstallation.postalCodeDesig;
  const services = [];
  singleInstallation.services.forEach((service) => {
    if (service.schedules.length > 0) {
      const serviceID = service.objectId;
      const serviceName = service.designation;
      const schedules = [];

      service.schedules.forEach((el) => {
        const weekdays =
          el.beginDay == el.endDay
            ? el.beginDay
            : el.beginDay + " a " + el.endDay;

        let hourDay;
        let morningHour;
        let afternoonHour;

        if (el.beginLunch == "" || el.endLunch == "") {
          hourDay = el.beginHour + " - " + el.endHour;
        } else {
          morningHour = el.beginHour + " - " + el.beginLunch;
          afternoonHour = el.endLunch + " - " + el.endHour;
        }

        schedules.push({
          weekdays: weekdays,
          hourDay: hourDay,
          morningHour: morningHour,
          afternoonHour: afternoonHour,
        });
      });
      services.push({
        serviceID: serviceID,
        serviceName: serviceName,
        schedules: schedules,
      });
    }
  });

  newSingleInstallationArray = {
    id: id,
    idParent: idParent,
    name: name,
    fullName: fullName,
    image: image,
    address: address,
    lat: lat,
    lng: lng,
    municipality: municipality,
    postalCode: postalCode,
    contacts: contacts,
    services: services,
  };

  return newSingleInstallationArray;
}

//FUNCTION TO CREATE AN ARRAY WITH ONLY THE IMPORTANT PROPERTIES FOR THE LIST
export function createUsedCarsArray(allUsedCars2, installations) {
  let newUsedCarsArray = [];
  allUsedCars2.forEach((car) => {
    if (car) {
      const model = car.model;
      const price = car.pricePvp;
      const formattedPrice = formatPriceNumber(price.toString(), "2", "€");
      const kms = car.kilometers;
      const year = car.year;
      const fid = car.financeId;
      const month = car.month;
      const fuelType = car.fuel;
      const version = car.commercialDescription;
      const brand = car.brand;
      const title = brand + " " + model;
      const fullTitle = title.toUpperCase() + " " + version;
      const oidDealer = car.dealerId;
      const vin = car.vin;
      let location = "";
      if (installations) {
        location = installations.find(
          (installation) => installation.id == oidDealer
        );
      }

      const mainImage = car.imageUrl;
      const color = car.color;
      const numberDoor = car.seats;
      const fee = car.tooltipDescription.monthPrice
        .toString()
        .replace(",", ".");

      const formattedFee = formatPriceNumber(fee.toString(), "2", "€/mês");
      const taeg = formatPriceNumber(car.taeg.toString(), 3, "%");

      const initEntrance = formatPriceNumber(
        car.tooltipDescription.depositValue.toString(),
        2,
        "€"
      );

      const nrPayments = car.tooltipDescription.months;
      const capitalToFinance = formatPriceNumber(
        car.tooltipDescription.capitalFinance.toString(),
        2,
        "€"
      );

      const legalInfo = car.legalInfo;

      location = location ? location.fullName : "";

      newUsedCarsArray.push({
        model: model,
        price: price,
        formattedPrice: formattedPrice,
        kms: kms,
        year: year,
        month: month,
        fuelType: fuelType,
        title: title,
        fid: fid,
        fullTitle: fullTitle,
        version: version,
        oidDealer: oidDealer,
        vin: vin,
        location: location,
        mainImage: mainImage,
        color: color,
        numberDoor: numberDoor,
        fee: fee ? fee : "",
        formattedFee: formattedFee ? formattedFee : "",
        taeg: taeg ? taeg : "",
        initEntrance: initEntrance ? initEntrance : "",
        nrPayments: nrPayments ? nrPayments : "",
        capitalToFinance: capitalToFinance ? capitalToFinance : "",
        legalInfo: legalInfo ? legalInfo : "",
      });
    }
  });

  return newUsedCarsArray;
}
//FUNCTION TO CREATE A MAPPED CAR OBJECT
export function mapUsedCar(car) {
  const modelID = car.carDetailCard.modelId;
  const model = car.carDetailCard.model;
  const price = car.carDetailCard.pricePvp;
  const formattedPrice = formatPriceNumber(price.toString(), "0", "€");
  const kms = car.carDetailCard.kilometers;
  const year = car.carDetailCard.year;
  const fid = car.carDetailCard.financeId;
  const month = car.carDetailCard.month;
  const fuelType = car.carDetailCard.fuel;
  const version = car.carDetailCard.commercialDescription;
  const brand = car.carDetailCard.brand;
  const title = brand + " " + model;
  const fullTitle = title.toUpperCase() + " " + version;
  const oidDealer = car.carDetailCard.dealerId;
  const vin = car.carDetailCard.vin;
  const mainImage = car.carDetailCard.imageUrl;
  const color = car.carDetailCard.color;
  const idFinancialProduct = 2;
  const numberDoor = car.carDetailCard.seats;
  const fee = car.carDetailFinance.monthlyPayment.toString().replace(",", ".");

  const formattedFee = formatPriceNumber(fee.toString(), "2", "€/mês");
  const taeg = formatPriceNumber(car.carDetailFinance.taeg.toString(), 3, "%");

  const initEntrance = formatPriceNumber(
    car.carDetailFinance.depositValue.toString(),
    2,
    "€"
  );

  const nrPayments = car.carDetailFinance.months;
  const capitalToFinance = formatPriceNumber(
    car.carDetailFinance.capitalFinance.toString(),
    2,
    "€"
  );

  const vfmg = car.carDetailFinance.residualValue;

  const legalInfo = car.carDetailCard.legalInfo;

  const dimensions = car.dimensions;

  const equipment = car.carDetailEquipmentByCategory.equipmentsByCategory;

  const availability = car.carDetailCard.availability == "STOCK" ? true : false;

  const viewers = car.viewers;

  const specifications = car.carDetailSpecification;

  const dealer = car.dealer;

  const creditTypes = car.creditTypes;

  let warranty = [];
  if (car.contracts.warrantyContract) {
    if (car.contracts.warrantyContract.warrantyContract) {
      warranty = car.contracts.warrantyContract.warrantyContract;
    }
  }
  let contracts = [];
  if (car.contracts.maintenanceContract) {
    if (car.contracts.maintenanceContract.maintenanceContract) {
      contracts = groupBy(
        car.contracts.maintenanceContract.maintenanceContract,
        "limitYearsValue"
      );
    }
  }

  const alternativeImage = car.carDetailImage.staticImages.externalImages;

  let mappedCar = {
    modelID: modelID,
    model: model,
    price: price,
    formattedPrice: formattedPrice,
    kms: kms,
    year: year,
    fid: fid,
    month: month,
    idFinancialProduct: idFinancialProduct,
    creditTypes: creditTypes,
    vfmg: vfmg,
    fuelType: fuelType,
    title: title,
    fullTitle: fullTitle,
    version: version,
    oidDealer: oidDealer,
    vin: vin,
    mainImage: mainImage,
    color: color,
    numberDoor: numberDoor,
    fee: fee ? fee : "",
    formattedFee: formattedFee ? formattedFee : "",
    taeg: taeg ? taeg : "",
    initEntrance: initEntrance ? initEntrance : "",
    nrPayments: nrPayments ? nrPayments : "",
    capitalToFinance: capitalToFinance ? capitalToFinance : "",
    legalInfo: legalInfo ? legalInfo : "",
    dimensions: dimensions,
    equipment: equipment,
    isAvailable: availability,
    viewers: viewers,
    specifications: specifications,
    dealer: dealer,
    warranty: warranty,
    contracts: contracts,
    alternativeImage: alternativeImage,
  };
  return mappedCar;

  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }
}

//FUNCTION TO CREATE A MAPPED CAR OBJECT
export function mapNewCar(car) {
  const modelID = car.carDetailCard.modelId;
  const model = car.carDetailCard.model;
  const price = car.carDetailCard.pricePvp;
  const formattedPrice = formatPriceNumber(price.toString(), "2", "€");
  const kms = car.carDetailCard.kilometers;
  const year = car.carDetailCard.year;
  const fid = car.carDetailCard.financeId;
  const month = car.carDetailCard.month;
  const fuelType = car.carDetailCard.fuel;
  const version = car.carDetailCard.commercialDescription;
  const brand = car.carDetailCard.brand;
  const title = brand + " " + model;
  const fullTitle = title.toUpperCase() + " " + version;
  const oidDealer = car.carDetailCard.dealerId;
  const vin = car.carDetailCard.vin;
  const mainImage = car.carDetailImage.rotationImages.rotatingExternalImages[0];
  const internalImages = car.carDetailImage.staticImages.internalImages;
  const color = car.carDetailCard.color;
  const numberDoor = car.carDetailCard.seats;
  const fee = car.carDetailFinance.monthlyPayment.toString().replace(",", ".");
  const idFinancialProduct = car.carDetailFinance.idFinancialProduct
    ? car.carDetailFinance.idFinancialProduct
    : 0;
  const financialProduct = car.creditTypes.find(
    (el) => el.produtctId == idFinancialProduct
  ).creditName;

  const formattedFee = formatPriceNumber(fee.toString(), "2", "€/mês");
  const taeg = formatPriceNumber(car.carDetailFinance.taeg.toString(), 3, "%");

  const initEntrance = formatPriceNumber(
    car.carDetailFinance.depositValue.toString(),
    2,
    "€"
  );

  const nrPayments = car.carDetailFinance.months;
  const capitalToFinance = formatPriceNumber(
    car.carDetailFinance.capitalFinance.toString(),
    2,
    "€"
  );

  const legalInfo = car.carDetailCard.legalInfo;

  const vfmg = formatPriceNumber(car.carDetailFinance.residualValue, 2, "€");

  const creditTypes = car.creditTypes;

  const dimensions = car.dimensions;

  const equipment = car.carDetailEquipmentByCategory.equipmentsByCategory;

  const availability = car.carDetailCard.availability == "STOCK" ? true : false;

  const viewers = car.viewers;

  const specifications = car.carDetailSpecification;

  let tempArr = [];
  car.carDetailImage.rotationImages.rotatingExternalImages.sort(
    (a, b) => a.priority - b.priority
  );
  car.carDetailImage.rotationImages.rotatingExternalImages.forEach(
    (element) => {
      tempArr.push(element);
    }
  );
  const allImages = tempArr;

  const alternativeImage = [
    tempArr[2],
    tempArr[5],
    tempArr[8],
    tempArr[11],
    tempArr[14],
    tempArr[17],
    tempArr[20],
    tempArr[23],
    tempArr[26],
    tempArr[29],
    tempArr[32],
    tempArr[35],
  ];

  const colorVariants = car.similarColors;

  const interiorColors = car.similarInteriors;

  let mappedCar = {
    modelID: modelID,
    model: model,
    price: price,
    formattedPrice: formattedPrice,
    kms: kms,
    year: year,
    idFinancialProduct: idFinancialProduct,
    financialProduct: financialProduct,
    vfmg: vfmg,
    fid: fid,
    month: month,
    allImages: allImages,
    fuelType: fuelType,
    title: title,
    internalImages: internalImages,
    fullTitle: fullTitle,
    version: version,
    oidDealer: oidDealer,
    vin: vin,
    mainImage: mainImage,
    color: color,
    numberDoor: numberDoor,
    fee: fee ? fee : "",
    formattedFee: formattedFee ? formattedFee : "",
    taeg: taeg ? taeg : "",
    creditTypes: creditTypes,
    initEntrance: initEntrance ? initEntrance : "",
    nrPayments: nrPayments ? nrPayments : "",
    capitalToFinance: capitalToFinance ? capitalToFinance : "",
    legalInfo: legalInfo ? legalInfo : "",
    dimensions: dimensions,
    equipment: equipment,
    isAvailable: availability,
    viewers: viewers,
    specifications: specifications,
    alternativeImage: alternativeImage,
    interiorColors: interiorColors,
    colorVariants: colorVariants,
  };
  return mappedCar;
}

//FUNCTION TO CREATE AN ARRAY WITH ONLY THE IMPORTANT PROPERTIES FOR THE LIST
export function createNewCarsArray(allNewCars) {
  return allNewCars;
}

//Funciton to map the new car array to the correct model

export function mapNewCarArray(carArray) {
  let allCars = [];
  carArray.forEach((car) => {
    car.vehicles.forEach((el) => {
      el.images.sort((a, b) => a.priority - b.priority);
    });

    let arrangedCar = {
      model: car.model,
      modelId: car.modelId,
      idFinancialProduct: car.vehicles[0].idFinancialProduct,
      financialProduct: car.vehicles[0].financialProduct,
      version: car.commercialDescription,
      description: car.commercialDescription,
      isAvailable: car.vehicles[0].availability == "STOCK" ? true : false,
      price: car.vehicles[0].prices[0].actualValue,
      vin: car.vehicles[0].vin,
      fee: Number(car.vehicles[0].monthlyPrice.toString().replace(",", ".")),
      formattedFee: formatPriceNumber(
        Number(
          car.vehicles[0].monthlyPrice.toString().replace(",", ".")
        ).toString(),
        "2",
        "€/mês"
      ),
      taeg: formatPriceNumber(car.vehicles[0].taeg.toString(), 3, "%"),
      initEntrance: formatPriceNumber(
        car.vehicles[0].depositValue.toString(),
        2,
        "€"
      ),
      nrPayments: car.vehicles[0].financingMonths,
      capitalToFinance: formatPriceNumber(
        car.vehicles[0].capitalFinance.toString(),
        2,
        "€"
      ),
      vfmg: formatPriceNumber(car.vehicles[0].residualValue.toString(), 2, "€"),
      image: car.vehicles[0].images[4]
        ? car.vehicles[0].images[4]
        : car.vehicles[0].images[0],
      rotatingImages: car.vehicles[0].images,
      fuel: car.vehicles[0].fuel,
      legalInfo: car.vehicles[0].legalInfo,
      color: car.vehicles[0].color,
      alternatives: car.vehicles,
      fid: car.vehicles[0].financeId,
    };
    allCars.push(arrangedCar);
  });
  return allCars;
  /*
  let availableCars = [];
  let unavailableCars = [];

  allCars.forEach((element) => {
    if (element.isAvailable) {
      availableCars.push(element);
    } else {
      unavailableCars.push(element);
    }
  });

  availableCars = workArray(availableCars);
  unavailableCars = workArray(unavailableCars);

  // APPLIES ALL THE NECESSARY WORK TO THE ARRAY (merge elements and remove equals)
  function workArray(array) {
    let i = 0;
    while (checkIfRepetition(array) && i < array.length) {
      let correctElement = mergeElements(array, array[i]);
      array = removeEqual(array[i], array);
      array.push(correctElement);
      i++;
    }
    return array;
  }
  // MERGES ALL ELEMENTS WITH SAME ID, MIXING TEH DIFFERENTE STATE
  function mergeElements(array, element) {
    let stateMix = [];
    array.forEach((ele) => {
      if (ele.model == element.model) {
        ele.carState[0].stateId = stateMix.length;
        stateMix = [...stateMix, ele.carState[0]];
      }
    });
    element.carState = stateMix;

    return element;
  }
  // CHECKS IF THERE IS ANY REPETITION WITHIN THE ARRAY
  function checkIfRepetition(array) {
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (i != j) {
          if (array[i].model == array[j].model) {
            return true;
          }
        }
      }
    }
    return false;
  }
  // RMOVES ITEMS IN ARRAY WITH EQUAL ID TO THE GIVE ELEMENT
  function removeEqual(element, array) {
    return array.filter((a) => a.model !== element.model);
  }
  console.log("fixed array", [...availableCars, ...unavailableCars]);
  return [...availableCars, ...unavailableCars]; */
}

export function mergeAll(tcap, dealer) {
  tcap.forEach((el) => {
    el["source"] = "tcap";
  });
  dealer.forEach((el) => {
    el["source"] = "dealer";
  });
  return tcap.concat(dealer).sort(({ date: a }, { date: b }) => b - a);
}

export function filterWarranty(garantias, modelId) {
  return garantias.find((el) => {
    return el.ID_MODEL == modelId;
  });
}

export function contratosArray(contratos) {
  const uniqueYears = [];
  const finalContratos = [];

  getUniques(contratos, "limitYearsValue").forEach((el) => {
    if (el.limitYearsValue > 0) {
      uniqueYears.push(el.limitYearsValue);
    }
  });

  uniqueYears.sort().forEach((el) => {
    const tab = el + " Anos";
    const options = contratos
      .filter((c) => {
        return c.limitYearsValue == el;
      })
      .map((c) => {
        c.label = c.contractName;
        c.value = c.pvpValue;

        return c;
      });

    finalContratos.push({ tab: tab, options: options });
  });

  return finalContratos;
}

export function manageSEO(
  seo,
  mainSeo,
  isSingle,
  dealer,
  installation = null,
  model = null,
  title = null
) {
  if (isSingle) {
    return {
      title: seo.single.seoTitle
        ? replaceBOVariable(
            seo.single.seoTitle,
            dealer,
            installation,
            model,
            title
          )
        : replaceBOVariable(mainSeo.seoTitle, dealer),
      meta: [
        {
          name: "description",
          content: seo.single.seoDescription
            ? replaceBOVariable(
                seo.single.seoDescription,
                dealer,
                installation,
                model,
                title
              )
            : replaceBOVariable(mainSeo.seoDescription, dealer),
        },
        {
          property: "og:title",
          content: seo.single.seoTitle
            ? replaceBOVariable(
                seo.single.seoTitle,
                dealer,
                installation,
                model,
                title
              )
            : replaceBOVariable(mainSeo.seoTitle, dealer),
        },
        { property: "og:site_name", content: "Toyota" },
        {
          property: "og:description",
          content: seo.single.seoDescription
            ? replaceBOVariable(
                seo.single.seoDescription,
                dealer,
                installation,
                model,
                title
              )
            : replaceBOVariable(mainSeo.seoDescription, dealer),
        },
        {
          property: "keywords",
          content: seo.single.seoKeywords
            ? seo.single.seoKeywords
            : mainSeo.seoKeywords,
        },
      ],
    };
  } else {
    return {
      title: seo.general.seoTitle
        ? replaceBOVariable(seo.general.seoTitle, dealer, installation, model)
        : replaceBOVariable(mainSeo.seoTitle, dealer),
      meta: [
        {
          name: "description",
          content: seo.general.seoDescription
            ? replaceBOVariable(
                seo.general.seoDescription,
                dealer,
                installation,
                model
              )
            : replaceBOVariable(mainSeo.seoDescription, dealer),
        },
        {
          property: "og:title",
          content: seo.seoTitle
            ? replaceBOVariable(
                seo.general.seoTitle,
                dealer,
                installation,
                model
              )
            : replaceBOVariable(mainSeo.seoTitle, dealer),
        },
        { property: "og:site_name", content: "Toyota" },
        {
          property: "og:description",
          content: seo.general.seoDescription
            ? replaceBOVariable(
                seo.general.seoDescription,
                dealer,
                installation,
                model
              )
            : replaceBOVariable(mainSeo.seoDescription, dealer),
        },
        {
          property: "keywords",
          content: seo.seoKeywords
            ? replaceBOVariable(seo.general.seoKeywords, dealer)
            : replaceBOVariable(mainSeo.seoKeywords, dealer),
        },
      ],
    };
  }
}

function replaceBOVariable(
  text,
  dealer,
  installation = null,
  model = null,
  title = null
) {
  let finalText = text;
  finalText = finalText.replaceAll("{#dealer}", dealer);
  finalText = finalText.replaceAll("{#dealerTag}", stringToSlug(dealer));
  if (installation) {
    finalText = finalText.replaceAll("{#installation}", installation);
  }
  if (model) {
    finalText = finalText.replaceAll("{#modelCar}", model);
  }
  if (title) {
    finalText = finalText.replaceAll("{#title}", title);
  }
  return finalText;
}

function stringToSlug(dealer) {
  const from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
  const to =
    "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";

  dealer = dealer.replace(/^\s+|\s+$/g, ""); // trim
  dealer = dealer.toLowerCase();

  for (var i = 0, l = from.length; i < l; i++) {
    dealer = dealer.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  dealer = dealer
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return dealer;
}
