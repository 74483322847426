<template>
  <div v-if="isAppReady" id="toyota" class="toyota">
    <Header />

    <router-view></router-view>
    <LoadingTransp v-if="isLoading" />
    <Footer />
    <CookiesModal
      v-if="
        this.$route.name != 'CookiesPolicy' &&
        modal != 'N' &&
        isMaintenance != 'true'
      "
    />
  </div>
  <div v-else id="toyota" class="toyota">
    <Loading />
  </div>
</template>

<script>
import router from "./router/index.js";

import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";

import CookiesModal from "@/components/Cookies/CookiesModal.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Loading from "@/components/Loading/Loading.vue";
import LoadingTransp from "@/components/Loading/TranpsLoading.vue";

import * as COOKIES from "@/functions/cookies/cookies.js";

import { currentUrl } from "@/services/endpoints";
import { mapMutations } from "vuex";

let gmtScript = document.createElement("script");
gmtScript.setAttribute(
  "src",
  process.env.VUE_APP_DOMAIN + "/wp-content/uploads/data/marketing.js"
);
document.head.appendChild(gmtScript);

//Cria a dataLayer
window.dataLayer = window.dataLayer || [];

export default {
  name: "App",
  components: {
    CookiesModal,
    Footer,
    Header,
    LoadingTransp,
    Loading,
  },
  computed: {
    isAppReady() {
      return this.$store.state.appReady;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    modal() {
      return this.$store.state.cookieModal;
    },
    cookies() {
      return this.$store.state.cookieSettings;
    },
  },
  beforeCreate() {
    if (this.isMaintenance != "true") {
      this.$store.dispatch("resetState", {
        state: "cookieModal",
        value: COOKIES.COOKIES_FUNCTIONS.getCookie("_rg_modal"),
      });
      if (!this.$store.state.appReady) {
        this.$store.dispatch("getInfoLoad");
      }
    } else {
      this.$store.state.appReady = true;
    }
  },
  created() {
    if (this.isMaintenance != "true") {
      this.updateUserState({
        sessionId: COOKIES.COOKIES_FUNCTIONS.getCookie("PHPSESSID"),
      });

      /** remove loginTokenCaetanoGO and reset store loginToken to null */
      const queryAction = this.$route.query?.action;
      if (queryAction && queryAction == "logout") {
        localStorage.removeItem("loginTokenCaetanoGO");
        this.updateUserState({
          loginToken: null,
          isCaetanoLogin: false,
        });
      }
    }
  },
  mounted() {
    if (this.isMaintenance != "true") {
      /** check if user loginToken is saved in localStorage */
      const storageToken = localStorage.getItem("loginToken");
      if (storageToken) {
        /** save in store token */
        this.updateUserState({
          loginToken: storageToken,
        });

        /** get user information */
        this.$store.dispatch("user/getUserData");
        this.$store.dispatch("user/getUserFavs");
      }
    }
  },
  methods: {
    ...mapMutations({
      updateUserState: "user/UPDATE_STATE",
    }),
    checkMetaTags() {
      const marketingStorage = JSON.parse(sessionStorage.getItem("marketing"));

      if (!marketingStorage) {
        return;
      }

      const marketingItem = marketingStorage.find((item) => {
        return item.url === currentUrl;
      });

      if (!marketingItem) {
        return;
      }

      const metaTags = [];

      if (
        "fbDomainVerification" in marketingItem
        && marketingItem.fbDomainVerification
      ) {
        metaTags.push({
          name: "facebook-domain-verification",
          content: marketingItem.fbDomainVerification,
        });
      }

      if (
        "googleSiteVerification" in marketingItem
        && marketingItem.googleSiteVerification
      ) {
        metaTags.push({
          name: "google-site-verification",
          content: marketingItem.googleSiteVerification,
        });
      }

      if (metaTags) {
        metaTags.forEach((metaTag) => {
          const metaTagElement = document.createElement("meta");

          metaTagElement.name = metaTag.name;
          metaTagElement.content = metaTag.content;

          document.querySelector("head").appendChild(metaTagElement);
        });
      }

      // Instancia Tag Manager, mas desativado.
      if (marketingItem.gtmId != "") {
        Vue.use(VueGtm, {
          id: marketingItem.gtmId,
          enabled: false,
          vueRouter: router,
        });

        if (COOKIES.checkRGCookies() == true) {
          // CRIAR LISTA DAS COOKIES BLOQUEADAS
          window.dataLayer.push({
            "gtm.blocklist": COOKIES.blockedList,
          });

          // INICIAR O TAG MANAGER
          this.$gtm.enable(true);
        } else {
          this.$gtm.enable(false);
        }
      }
    },
  },
  watch: {
    isAppReady() {
      if (this.isAppReady) {
        this.checkMetaTags();
      }
    },
    modal() {
      //SE O VALOR DA COOKIE MODAL FOR "N" = NÃO ABRIR MODAL (JÁ EXISTEM CONFIGURAÇÕES DE COOKIES)
      if (this.modal == "N") {
        this.checkMetaTags();
      }
    },
    cookies() {
      this.checkMetaTags();
    },
    $route(to) {
      // watch route and save in sessionStorage the path
      // we will use it to push route after login.
      // stay same page...
      sessionStorage.setItem("routePath", to.path + to.hash);
    },
  },
};
</script>
<style lang="scss">
#toyota {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: 100vh;
}

html {
  box-sizing: border-box;

  //Anchor smooth scroll, counts the size of the fixed menu
  scroll-behavior: smooth;
  scroll-padding-top: calc($menuHeight * 2);

  //Text more readable
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  //General CSS for text
  font-family: $fontBook;
  color: $tmeGrey1;
  font-size: $mainFontSize;
  font-weight: $regular;
  line-height: $lineHeight;
  word-break: normal;
  text-align: center;

  //CSS to ensure the footer stays in the bottom, even when the page is smaller than the viewport
  display: flex;
  flex-direction: column;

  position: relative;

  //CSS Page Size
  min-height: 100vh;
  margin: 0 auto;
  max-width: $maxViewport;
  overflow-x: hidden;

  .wrapper {
    flex: 1;
    margin: 0 auto;
    margin-top: calc($menuHeight * 2);
    width: $viewportWidthLg;

    @include respond-to-max("md") {
      margin-top: $menuHeight;
      width: $viewportWidthSm;
      &.main {
        width: 100% !important;
      }
    }
  }
}
</style>
