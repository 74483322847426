const PERFORMANCE_COOKIES = [
  ["Google UA", "ua", ["_ga", "_gid", "_gcl"]],
  ["Google GA", "ga", ["__utm"]],
];
const TARGETING_COOKIES = [
  ["hotjar", "hjtc", ["_hj"]],
  ["Clarity", "clarity", ["_clsk", "_clck", "MUID"]],
  ["Facebook", "fb", ["_fbc", "_fbp", "fbm"]],
  ["Atlassian", "ajs", ["ajs"]],
  ["Zoho", "zab", ["zab", "zsr", "zps", "zia", "zft"]],
  ["Outros", "customPixels", ["zsce"]],
  ["Outros", "customScripts", [""]],
];

export { PERFORMANCE_COOKIES, TARGETING_COOKIES };
