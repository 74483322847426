export let domain;
export let simulator;
export let accessToken;
export let simulatorAccessToken;
export let currentUrl;
export let carByPlate;
export let carByPlateAccessToken;
export let services;
export let servicesAccessToken;
export let contracts;
export let contractsAccessToken;
export let newDomain;

currentUrl = window.location.origin.replace(/(^\w+:|^)\/\//, ""); // Remove o protocolo

if (currentUrl.startsWith("www.")) {
  currentUrl = currentUrl.substring(4); // Remove o "www."
}

currentUrl = currentUrl
  .split(".", 1)[0]
  .replace("minisitetoyota", "")
  .replace("minisitelexus", "");

currentUrl =
  currentUrl.substring(0, 3) == "dev" ? currentUrl.slice(3) : currentUrl;
currentUrl =
  currentUrl.substring(0, 4) == "demo" ? currentUrl.slice(4) : currentUrl;

if (currentUrl == "vue") currentUrl = "caetanoauto";

domain = process.env.VUE_APP_DOMAIN;
accessToken = process.env.VUE_APP_DOMAIN_ACCESS_TOKEN;

newDomain = process.env.VUE_APP_NEW_DOMAIN;

simulator = process.env.VUE_APP_TFS;
simulatorAccessToken = process.env.VUE_APP_TFS_ACCESS_TOKEN;

carByPlate = process.env.VUE_APP_CAR_BY_PLATE;
carByPlateAccessToken = process.env.VUE_APP_CAR_BY_PLATE_ACCESS_TOKEN;

services = process.env.VUE_APP_SERVICES;
servicesAccessToken = process.env.VUE_APP_SERVICES_ACCESS_TOKEN;

contracts = process.env.VUE_APP_CONTRACTS;
contractsAccessToken = process.env.VUE_APP_CONTRACTS_ACCESS_TOKEN;

const apiPath = "wp-json/tcap";

export const generalHeaders = {
  "Content-Type": "application/json",
  accessToken: accessToken,
};

export const url = {
  /* MAIN wp */
  siteinfo: domain + apiPath + "/info",
  contractsTemp: domain + apiPath + "/contracts",
  newSiteInfo: newDomain,

  /* DIRECT */
  carByPlate: carByPlate,
  services: services,
  contracts: contracts,
  simulator: simulator,
};
