export function formatPriceNumber(value, decimals, symbol = "") {
  //const rounded = Math.round(value * 100) / 100;
  return (
    Number(value.toString().replace(",", "."))
      .toFixed(decimals)
      .toString()
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
    " " +
    symbol
  );
}

export function formatPriceNumberNoSpace(value, decimals, symbol = "") {
  //const rounded = Math.round(value * 100) / 100;
  return (
    Number(value.toString().replace(",", "."))
      .toFixed(decimals)
      .toString()
      .replace(".", ",") +
    " " +
    symbol
  );
}

export function formatToSlug(string = "") {
  return string
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replaceAll(" -", "")
    .replaceAll(" ", "-")
    .replaceAll(/[^a-zA-Z- ]/g, "")
    .toLowerCase();
}

export function generateUUID() {
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function convertDateToStr(val, expiration = false) {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const date = new Date(val);
  const expirationTime = 30;
  let expirationDate = new Date(val);
  expirationDate.setDate(expirationDate.getDate() + expirationTime);
  const day = expiration ? expirationDate.getDate() : date.getDate();
  const month = expiration
    ? months[expirationDate.getMonth()]
    : months[date.getMonth()];
  const year = expiration ? expirationDate.getFullYear() : date.getFullYear();

  return `${day} de ${month} de ${year}`;
}
