<template>
  <div :class="headerClass + 'header'">
    <TopMenu @openMenu="openMenu" />
    <Navbar v-if="isMaintenance != 'true'" @openMenu="openMenu" />

    <Modal v-if="user.modalOpen" />
    <ValidateAccount v-if="showValidateModal" />
  </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import TopMenu from "@/components/Header/TopMenu.vue";
import Modal from "@/components/Header/Login/LoginModal.vue";
import ValidateAccount from "@/components/Header/Login/ValidateAccount.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Header",
  components: {
    Navbar,
    TopMenu,
    Modal,
    ValidateAccount,
  },
  data() {
    return {
      headerClass: "close ",
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      showValidateModal: "user/checkValidationAccount",
    }),
  },
  methods: {
    openMenu() {
      this.headerClass = this.headerClass == "close " ? "open " : "close ";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Header";
</style>
