<template>
  <div :class="blockClass + 'heading-block'">
    <h2 v-if="data.blockTitle" class="title">{{ data.blockTitle }}</h2>
    <p
      v-if="data.blockText"
      :class="textClass + 'text'"
      v-html="data.blockText"
    ></p>
  </div>
</template>

<script>
export default {
  name: "HeadingBlock",
  props: {
    data: { type: [Object, Array] },
    textClass: { type: String, default: "" },
    blockClass: { type: String, default: "" },
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
    };
  },
  created() {
    if (this.data.blockTitle) {
      this.data.blockTitle = this.data.blockTitle.replaceAll(
        "{#dealer}",
        this.dealerName
      );
    }
    if (this.data.blockText) {
      this.data.blockText = this.data.blockText.replaceAll(
        "{#dealer}",
        this.dealerName
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@import "/Blocks";
</style>
