// Form state: form page ok / nok

const state = () => ({
  formSuccess: {},
  formError: {},
});

const getters = {
  getProp: (state) => (key) => {
    return key ? state[key] : undefined;
  },
};

const mutations = {
  UPDATE_STATE(state, payload) {
    state = Object.assign(state, payload);
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
