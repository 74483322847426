<template>
  <input
    v-if="data.type == 'phone'"
    :type="data.type"
    :name="data.name"
    :id="id + '-' + data.name"
    :data-label="data.label"
    @input="validateInput"
    :data-type="data.validation.type"
    :data-errormessage="data.errormessage"
    :minlength="data.validation.minLength || 9"
    :maxlength="data.validation.maxLength || 9"
    class="form__control required"
  />
  <div
    v-else-if="
      data.name == 'nif' || data.name == 'postalCode' || data.name == 'plate'
    "
  >
    <input
      :type="data.type"
      :name="data.name"
      :id="id + '-' + data.name"
      :data-label="data.label"
      v-model="input"
      :data-type="data.validation ? data.validation.type : ''"
      :data-errormessage="data.errormessage"
      :data-country="data.validation ? data.validation.country : ''"
      class="form__control required inputText"
      @change="updateLabel"
    />
    <label
      :for="id + '-' + data.name"
      :class="isFocus ? 'floating-label focused' : 'floating-label'"
      >{{ data.label }}</label
    >
  </div>

  <input
    v-else-if="data.type == 'time'"
    :type="data.type"
    :name="data.name"
    :id="id + '-' + data.name"
    :data-label="data.label"
    :data-type="data.validation.type"
    :data-errormessage="data.errormessage"
    onfocus="this.showPicker()"
    :data-mintime="data.validation.min"
    :data-maxtime="data.validation.max"
    class="form__control required"
  />
  <input
    v-else-if="data.type == 'date'"
    :type="data.type"
    :name="data.name"
    onfocus="this.showPicker()"
    :id="id + '-' + data.name"
    :data-label="data.label"
    :data-type="data.validation.type"
    :data-errormessage="data.errormessage"
    :min="new Date().toISOString().split('T')[0]"
    :data-mindate="
      data.validation.min == '{#today}'
        ? new Date().toISOString().split('T')[0]
        : data.validation.min
    "
    :data-maxdate="data.validation.max"
    class="form__control required"
  />
  <div class="text-input" v-else-if="data.type == 'hidden'">
    <input
      :type="data.type"
      :name="data.name"
      :id="id + '-' + data.name"
      :data-errormessage="data.errormessage"
      :value="data.value"
      class="form__control required inputText"
    />
    <label
      :for="id + '-' + data.name"
      :class="isFocus ? 'floating-label focused' : 'floating-label'"
      >{{ data.label }}</label
    >
  </div>
  <div class="text-input" v-else>
    <input
      v-if="data.type == 'email'"
      :type="data.type"
      :name="data.name"
      :id="id + '-' + data.name"
      :data-errormessage="data.errormessage"
      :data-label="data.label"
      v-model="input"
      @input="validateInput"
      class="form__control required inputText"
      @change="updateLabel"
    />
    <input
      v-else
      :type="data.type"
      :name="data.name"
      :id="id + '-' + data.name"
      :data-errormessage="data.errormessage"
      :data-label="data.label"
      v-model="input"
      class="form__control required inputText"
      @change="updateLabel"
    />
    <label
      :for="id + '-' + data.name"
      :class="isFocus ? 'floating-label focused' : 'floating-label'"
      >{{ data.label }}</label
    >
  </div>
</template>

<script>
export default {
  name: "InputField",
  data() {
    return {
      input: "",
      isFocus: false,
      isValid: true,
    };
  },
  props: {
    data: { type: Object },
    id: { type: String },
  },
  methods: {
    updateLabel() {
      if (this.input.length > 0) {
        this.isFocus = true;
      } else {
        this.isFocus = false;
      }
      this.validateInput();
      this.$emit("update:value", this.input);
    },
    validateInput() {
      if (this.data.name === "email") {
        this.isValid = this.validateEmail(this.input);
      }
      if (this.data.name === "phone") {
        //console.log("here");
        this.isValid = this.validatePhone(this.input);
      }
      if (!this.isValid) {
        if (this.data.name === "phone") {
          this.$emit("input-error", "Número inválido");
        } else if (this.data.name === "email") {
          this.$emit("input-error", "Email inválido");
        } else {
          this.$emit("input-error", this.data.messageError);
        }
      } else this.$emit("input-error", "");
    },
    validateEmail(email) {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return re.test(email);
    },

    validatePhone(phone) {
      const isNumeric = /^\d+$/.test(phone);
      const isValidLength = phone.length >= 9 && phone.length <= 9;
      return isNumeric && isValidLength;
    },
  },
  watch: {
    input(newValue) {
      if (this.data.name === "phone") {
        let cleanedInput = newValue.replace(/[^0-9]/g, "");
        if (cleanedInput.length > 9) {
          cleanedInput = cleanedInput.substring(0, 9);
        }
        this.input = cleanedInput;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
