import axios from "axios";
import * as endpoints from "./endpoints";

let companyId = 5;

export async function infoLoad() {
  const customHeader = {
    subDomain: endpoints.currentUrl,
  };

  try {
    const response = await axios.get(endpoints.url.siteinfo, {
      headers: { ...endpoints.generalHeaders, ...customHeader },
    });
    companyId = response.data.response.dealerWPInfo.companyId;
    return response.data;
  } catch (error) {
    return error;
  }
}

// ? availability: [1] - stock ou encomenda (2 == nao disponivel/encomenda; 1 == stock)

export async function getFiltersNew(appliedFilter) {
  try {
    const response = await axios.post(
      endpoints.url.newSiteInfo + "vehicle/dropdowns?conditionId=1&blocked=0",
      {
        filters: { ...appliedFilter },
      },
      {
        headers: {
          companyId: companyId,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function getFiltersUsed(appliedFilter) {
  try {
    const response = await axios.post(
      endpoints.url.newSiteInfo + "vehicle/dropdowns?conditionId=2&blocked=0",
      {
        filters: { ...appliedFilter },
      },
      {
        headers: {
          companyId: companyId,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function filterCar(appliedFilter, page = 1) {
  try {
    const response = await axios.post(
      endpoints.url.newSiteInfo +
        `search/v2?numberElements=${
          // eslint-disable-next-line no-prototype-builtins
          appliedFilter.hasOwnProperty("elements") ? appliedFilter.elements : 12
        }&orderBy=${
          // eslint-disable-next-line no-prototype-builtins
          appliedFilter.order.hasOwnProperty("order")
            ? appliedFilter.order.order
            : "asc"
        }&page=${page}&showReservation=1&sort=${
          // eslint-disable-next-line no-prototype-builtins
          appliedFilter.order.hasOwnProperty("type")
            ? appliedFilter.order.type
            : "price"
        }&withUrl=true`,
      {
        filters: { ...appliedFilter.filters, condition: [2], blocked: [0] },
      },
      {
        headers: {
          companyId: companyId,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return { ...response.data, ...response.pagination };
  } catch (error) {
    return error;
  }
}

export async function filterCarNew(appliedFilter, page = 1) {
  try {
    const response = await axios.post(
      endpoints.url.newSiteInfo +
        `search/v2/group?numberElements=12&orderBy=${
          // eslint-disable-next-line no-prototype-builtins
          appliedFilter.order.hasOwnProperty("order")
            ? appliedFilter.order.order
            : "asc"
        }&page=${page}&showReservation=1&sort=${
          // eslint-disable-next-line no-prototype-builtins
          appliedFilter.order.hasOwnProperty("type")
            ? appliedFilter.order.type
            : "availability"
        }&withUrl=true`,
      {
        filters: { ...appliedFilter.filters, condition: [1], blocked: [0] },
      },
      {
        headers: {
          companyId: companyId,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return { ...response.data, ...response.pagination };
  } catch (error) {
    return error;
  }
}

export async function loadInstalations(type = null) {
  let query = "";
  if (type === "N") {
    query = "&services=SC00020127&services=SC00020944";
  } else if (type === "U") {
    query = "&services=SC00020128&services=SC00020945";
  } else if (type === "O") {
    query = "&services=SC00020130&services=SC00020946";
  }
  console.log(type, query);
  try {
    const response = await axios.get(
      endpoints.url.newSiteInfo + "dealers/v2?detail=true" + query,
      {
        headers: {
          companyId: companyId,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function simulateFinance(values) {
  try {
    const response = await axios.get(
      endpoints.url.newSiteInfo +
        "finance/tfs/" +
        values.vin +
        "?finalPrice=" +
        values.finalPrice +
        "&initialValue=" +
        Number(values.initEntrance).toFixed(2) +
        "&nrPayments=" +
        values.monthPeriod +
        "&oidDealerParent=" +
        values.oidDealerParent +
        "&productId=" +
        values.idProduct +
        "&residualValue=" +
        values.vmfg,
      {
        headers: {
          companyId: companyId,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function retakePlate(plate) {
  try {
    const response = await axios.get(
      endpoints.url.newSiteInfo + "retake/plate/v2/" + plate,
      {
        headers: {
          providerId: 2,
          companyId: companyId,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function carBrands() {
  try {
    const response = await axios.get(endpoints.url.newSiteInfo + "brands", {
      headers: {
        providerId: 2,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function carModel(brandId, year) {
  try {
    const response = await axios.get(
      endpoints.url.newSiteInfo +
        "brands/" +
        brandId +
        "/models/v2?year=" +
        year,
      {
        headers: {
          providerId: 2,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function retakeValue(body, userToken) {
  try {
    const response = await axios.post(
      endpoints.url.newSiteInfo + "retake/phase1/v2",
      body,
      {
        headers: {
          providerId: 2,
          companyId: companyId,
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    console.log(error.response);
    if (error.response.data.code == -10) {
      return error.response.data;
    }
  }
}
export async function getFavorites(userToken) {
  try {
    const response = await axios.get(endpoints.url.newSiteInfo + "favorite", {
      headers: {
        providerId: 2,
        companyId: companyId,
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function checkFavorite(userToken, vin) {
  try {
    const response = await axios.get(
      endpoints.url.newSiteInfo + "favorite/" + vin,
      {
        headers: {
          providerId: 2,
          companyId: companyId,
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
export async function setFavorites(userToken, body) {
  try {
    const response = await axios.post(
      endpoints.url.newSiteInfo + "favorite",
      body,
      {
        headers: {
          providerId: 2,
          companyId: companyId,
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
export async function removeFavorites(userToken, body) {
  try {
    const response = await axios.put(
      endpoints.url.newSiteInfo + "favorite",
      body,
      {
        headers: {
          providerId: 2,
          companyId: companyId,
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function formOsSubmit(body, userToken) {
  try {
    const response = await axios.post(
      endpoints.url.newSiteInfo + "payments/easypay",
      body,
      {
        headers: {
          companyId: companyId,
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function loadCarByVin(values) {
  try {
    const response = await axios.get(
      endpoints.url.newSiteInfo + "vehicle/" + values.vin,
      {
        headers: {
          companyId: companyId,
          sessionId: values.sessionId,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function getModels() {
  try {
    const response = await axios.get(
      endpoints.url.newSiteInfo + "vehicle/models?condition=1",
      {
        headers: {
          companyId: companyId,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function loadCarByPlate(plate) {
  try {
    const response = await axios.get(
      endpoints.url.carByPlate + plate + "/returnType/json",
      {
        headers: { accessToken: endpoints.carByPlateAccessToken },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function loadCarInfo(versionCode, comercialModelCode) {
  try {
    const response = await axios.get(
      endpoints.url.services +
        "car/modelCode/" +
        comercialModelCode +
        "/suffix/" +
        versionCode,
      {
        headers: { accessToken: endpoints.servicesAccessToken },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function loadContracts(values) {
  const customHeader = {
    plate: values.plate,
    kms: values.kms,
  };

  try {
    const response = await axios.get(endpoints.url.contractsTemp, {
      headers: { ...endpoints.generalHeaders, ...customHeader },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
}
/* export async function loadContracts(values) {
  console.log("values", values);
  try {
    const response = await axios.get(
      endpoints.url.contracts +
        "?plate=" +
        values.plate +
        "&contractType=U&km=" +
        values.kms,
      {
        headers: { accessToken: endpoints.contractsAccessToken },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
} */
