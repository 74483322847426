<template>
  <div class="loading-container-transp">
    <img v-if="isLexus" class="loading" src="@/assets/loadingLexus.svg" />
    <img v-else class="loading" src="@/assets/loading.svg" />
  </div>
</template>
<script>
export default {
  name: "TranspLoading",
  data() {
    return {
      isLexus: this.$store.state.isLexus,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "/Loading";
</style>
