<template>
  <div :class="classSection + 'usedCars-container row'">
    <div
      class="fluid-element elm-mobile"
      style="height: 100%"
      v-if="filterToggle"
    >
      <div class="overlay"></div>
    </div>
    <template v-if="page != 'homepage'">
      <div
        v-for="(car, i) in cars"
        :key="i"
        :class="
          i == index && !filterToggle
            ? 'all-col card'
            : i == index && filterToggle
            ? 'half-col card'
            : 'card'
        "
      >
        <UsedCars v-if="i != index" :data="car" />
        <div v-else>
          <div class="used-banner">
            <div class="img-box">
              <img :src="image" alt="" />
            </div>
            <div class="ban-text">
              <div class="ban-title">
                Não encontra o usado que procura?<br />
                Nós ajudamos.
              </div>
              <router-link to="/contacto">Quero ser contactado</router-link>
            </div>
          </div>
        </div>
      </div>
    </template>

    <UsedCars v-else v-for="(car, i) in data" :key="i" :data="car" />

    <Card v-if="page == 'homepage'">
      <template v-slot:content>
        <CardCTA link="/usados">
          <img src="@/assets/arrow-grey.svg" />

          <p class="link-cta">Conheça todas as viaturas</p>
        </CardCTA>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card/Card.vue";
import CardCTA from "@/components/Card/CardCTA.vue";
import UsedCars from "@/components/UsedCars/UsedCars.vue";
export default {
  name: "UsedCarsSection",
  components: {
    Card,
    CardCTA,
    UsedCars,
  },
  props: {
    data: { type: Array },
    page: { type: String, default: "" },
    classSection: { type: String, default: "" },
    filterToggle: { type: Boolean },
  },
  computed: {
    cars() {
      let arr = [...this.data];

      arr.forEach((el, i) => {
        if (el == "item") {
          arr.splice(i, 1);
        }
      });
      arr.splice(this.index, 0, "item");

      return arr;
    },
    index() {
      return this.data.length > 6 ? 6 : this.data.length;
    },
    image() {
      if (!this.$store.state.isLexus) {
        return require("@/assets/banner-image.png");
      } else {
        return require("@/assets/lexus-banner-image.webp");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/UsedCars";
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.half-col {
  @include respond-to-min("md") {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  margin-top: 2rem;

  .used-banner {
    display: flex;

    background-color: $blackColor;
    position: relative;
    @include respond-to-max("md") {
      flex-wrap: wrap;
      padding-bottom: 3rem;
    }
    .img-box {
      transform: translateY(-2rem);
      position: relative;
      max-width: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @include respond-to-max("md") {
        max-width: 90%;
        margin-left: auto;
      }
    }

    .ban-text {
      margin: auto;
      text-align: center;
      color: $primaryWhiteColor;
      padding: 1rem 0;
      .ban-title {
        @include respond-to-max("md") {
          font-size: $bigFontSize;
          max-width: 90%;
        }
        font-size: $biggerFontSize;
        line-height: 1.2;
        max-width: 60%;
        position: relative;
        margin: 0 auto;
        text-align: center;
      }
      a {
        display: block;
        color: $primaryWhiteColor;
        @include respond-to-max("md") {
          font-size: $smallFontSize;
          max-width: 90%;
        }
      }
    }
  }
}
.all-col {
  @include respond-to-min("md") {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  margin-top: 2rem;
  .used-banner {
    display: flex;

    background-color: $blackColor;
    position: relative;
    @include respond-to-max("md") {
      flex-wrap: wrap;
      padding-bottom: 3rem;
    }

    .img-box {
      transform: translateY(-2rem);
      position: relative;
      max-width: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @include respond-to-max("md") {
        max-width: 90%;
        margin-left: auto;
      }
    }
    .ban-text {
      margin: auto;
      text-align: center;
      color: $primaryWhiteColor;
      padding: 1rem 0;
      @include respond-to-max("md") {
        padding: 0;
      }
      .ban-title {
        @include respond-to-max("md") {
          font-size: $bigFontSize;
          max-width: 90%;
          text-align: center;
          margin: 0 auto;
        }
        line-height: 1.2;
        font-size: $biggestFontSize;
        max-width: 60%;
        margin: 0 auto;
        text-align: center;
        position: relative;
      }
      a {
        display: block;
        color: $primaryWhiteColor;
        text-align: center;
        @include respond-to-max("md") {
          font-size: $smallFontSize;
        }
      }
    }
  }
}
</style>
